import { PromoPhase } from 'features/promoMode/promo-phase.class'
import Countdown from './countdown'
import NoCountdown from './noCountdown'

type Props = {
    phase: PromoPhase
}

function PromoBanner({ phase }: Props): JSX.Element {
    if (!phase.countdown) {
        return <NoCountdown phase={phase} />
    }

    return <Countdown phase={phase} />
}

export default PromoBanner
