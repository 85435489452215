import { INTEGRATION_ENDPOINT } from 'config/constants'
import { CheckoutState } from 'contexts/checkout/type'
import { mapRegisterCustomerPayload } from 'utils/mapRegisterCustomerPayload'
import { validateRegisterCustomerBody } from 'utils/validateRegisterCustomerBody'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL

interface RegisterCustomerResponseJson {
    nextServiceDate?: string
    errorMessage?: string
    referralCode?: string
    referralLink?: string
    contractId?: number
    accountId?: number
    propertyId?: number
    contractDate?: string
    marketingOptIn?: boolean
    correspondencePropertyId?: number | undefined
    errorCode?: string
}

export const registerCustomer = async (
    state: CheckoutState
): Promise<RegisterCustomerResponseJson> => {
    const registerCustomerPayload = mapRegisterCustomerPayload(state)
    const validatedBody = validateRegisterCustomerBody(registerCustomerPayload)

    if (validatedBody.valid === false) {
        return {
            ...validatedBody,
        } as RegisterCustomerResponseJson
    }

    const registerCustomerResponse = await fetch(
        `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/customer/register`,
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(registerCustomerPayload),
        }
    )

    const { data, errorCode } = await registerCustomerResponse.json()
    return { ...data, ...errorCode } as RegisterCustomerResponseJson
}
