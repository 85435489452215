import Button from 'components/button'
import { PHONE_NUMBER, PHONE_NUMBER_LINK } from 'config/constants'
import { ReactComponent as XRedIcon } from '../../../../assets/x-red-circle.svg'
import styles from './errorScreen.module.scss'

interface Props {
    error: {
        title: string
        message: string
    }
    handleTryAgainClick: () => void
}

function ErrorScreen({ error, handleTryAgainClick }: Props) {
    return (
        <div className={styles.errorScreenContainer}>
            <div className={styles.icon}>
                <XRedIcon />
            </div>
            <hr />
            <h2>{error.title}</h2>
            <h3>{error.message}</h3>
            <Button onClick={handleTryAgainClick}>Try again</Button>
            <span>
                If the problem persists, contact us at:{' '}
                <a href={PHONE_NUMBER_LINK} rel="noreferrer">
                    {PHONE_NUMBER}
                </a>
                .
            </span>
        </div>
    )
}

export default ErrorScreen
