import { useState } from 'react'
import Collapsible from 'components/collapsible'
import Button from 'components/button'
import styles from './index.module.scss'

function FAQs() {
    const [isQ1Collapsed, setIsQ1Collapsed] = useState<boolean>(true)
    const [isQ2Collapsed, setIsQ2Collapsed] = useState<boolean>(true)
    const [isQ3Collapsed, setIsQ3Collapsed] = useState<boolean>(true)
    const [isQ4Collapsed, setIsQ4Collapsed] = useState<boolean>(true)
    const [isQ5Collapsed, setIsQ5Collapsed] = useState<boolean>(true)
    const [isQ6Collapsed, setIsQ6Collapsed] = useState<boolean>(true)
    const [isQ7Collapsed, setIsQ7Collapsed] = useState<boolean>(true)

    return (
        <div className={styles.container}>
            <h1>Frequently Asked Questions</h1>
            <Collapsible
                header={<h4>What are boiler care plans?</h4>}
                collapsed={isQ1Collapsed}
                onHeaderClick={() => setIsQ1Collapsed(!isQ1Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        A Hometree boiler care plan is a cover policy that provides professional
                        repairs if your boiler encounters a fault. You&apos;ll often hear boiler
                        care plans referred to as boiler breakdown cover, boiler cover or gas boiler
                        cover - they all mean the same thing.
                    </p>
                    <p>
                        Boiler care plans can help you better manage the unexpected and unwanted
                        costs of repairs or a replacement if your boiler breaks down. Boiler care
                        plans can also be supplemented with extended cover for the rest of your
                        home.
                    </p>
                    <p>
                        Hometree offers plans that cover your wider central heating, plumbing,
                        drains and electrics.
                    </p>
                </span>
            </Collapsible>
            <Collapsible
                header={<h4>What&apos;s included in Hometree boiler care plans?</h4>}
                collapsed={isQ2Collapsed}
                onHeaderClick={() => setIsQ2Collapsed(!isQ2Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        All of our core care plans include an annual boiler service and cover the
                        cost of any necessary repairs to the boiler, flues and controls.
                    </p>
                    <p>
                        You will also benefit from access to our UK based 24/7 helpline, and in the
                        event of a breakdown we will book a local engineer to come see you ASAP. If
                        your boiler can&apos;t be repaired, we can offer you 15% off a brand new
                        energy efficient A-rated replacement boiler and its installation.
                    </p>
                </span>
            </Collapsible>
            <Collapsible
                header={<h4>What do Hometree plans offer?</h4>}
                collapsed={isQ3Collapsed}
                onHeaderClick={() => setIsQ3Collapsed(!isQ3Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        All our core boiler care plans offer annual servicing and emergency repairs
                        in the event of a breakdown. You can choose your ideal level of cover and
                        call-out fee to suit your specific needs and budget. The Your Boiler plan
                        covers the boiler and controls, while the Your Heating plan includes your
                        wider central heating system. Finally, for added peace of mind, you can
                        choose the Your Home plan which also includes plumbing, drains and home
                        electrics*.
                    </p>
                    <p>
                        Hometree offers unlimited repairs. You can compare our packages on our
                        homeowner plans page starting{' '}
                        <a
                            href="https://hometree.comparethemarket.com/?AFFCLIE=CM01&ctmconsent=sn:1,f:0,m:0,p:0,r:0"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>{' '}
                        and select your preferred choice of call-out fee.
                    </p>
                    <p>
                        If you are a landlord, see our landlord boiler plans{' '}
                        <a
                            href="https://hometree.comparethemarket.com/products/compare-the-market-landlord?package=12&contribution=95&billing=monthly&customerType=landlord"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        .
                    </p>
                    <p>*terms and conditions apply</p>
                </span>
            </Collapsible>
            <Collapsible
                header={<h4>Do your policies protect all boilers?</h4>}
                collapsed={isQ4Collapsed}
                onHeaderClick={() => setIsQ4Collapsed(!isQ4Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        Unlike other providers, Hometree covers all brands, makes and models of
                        natural gas boilers, regardless of the age. So you&apos;re more protected if
                        you encounter a fault.
                    </p>
                    <p>
                        When completing your care plan onboarding, we&apos;ll ask for the details of
                        the boiler you are insuring. This is just so we can better serve you in the
                        event of a breakdown. If you&apos;re unsure what type of boiler you have,
                        just check the logo and model number near the controls.
                    </p>
                </span>
            </Collapsible>
            <Collapsible
                header={
                    <h4>
                        What is an annual service and why do I need one and will I be charged a
                        call-out fee?
                    </h4>
                }
                collapsed={isQ5Collapsed}
                onHeaderClick={() => setIsQ5Collapsed(!isQ5Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        An annual service is an opportunity for a gas-safe engineer to check your
                        system is running smoothly and flag any issues that might be developing.
                        Most boilers last between 10 to 15 years and regular maintenance is advised
                        by manufacturers to ensure optimal performance. Remember, servicing your
                        boiler is less expensive than replacing it. Hometree conducts all our boiler
                        servicing in the summer months to ensure our engineers are free to attend
                        emergencies in the winter months.
                    </p>
                    <p>
                        With all Hometree core care plans, you receive a yearly boiler service as
                        part of your boiler care plan package. There is no call-out fee and no extra
                        fees for the service, it is included in your plan. We cover gas boiler types
                        of all ages and from all makes and manufacturers. During your annual boiler
                        service, your engineer will complete visual checks and internal tests.
                    </p>
                </span>
            </Collapsible>
            <Collapsible
                header={<h4>What happens if my boiler is broken and I need to raise a claim?</h4>}
                collapsed={isQ6Collapsed}
                onHeaderClick={() => setIsQ6Collapsed(!isQ6Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        If you&apos;re a Hometree customer, book online{' '}
                        <a
                            href="https://portal.hometree.co.uk/auth"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        . We&apos;ll arrange for a Gas Safe engineer to evaluate your boiler and
                        find the problem. It could be an issue with the hot water supply, or perhaps
                        the boiler controls are faulty. Most of the time, our engineers can fix a
                        broken boiler, but sometimes a replacement is necessary.
                    </p>
                    <p>
                        If our engineers regard your boiler beyond economical repair, we can offer
                        you 15% off a brand new energy efficient A-rated replacement boiler and its
                        installation.
                    </p>
                </span>
            </Collapsible>
            <Collapsible
                header={
                    <h4>
                        What&apos;s the difference between Hometree boiler, central heating cover
                        and home cover?
                    </h4>
                }
                collapsed={isQ7Collapsed}
                onHeaderClick={() => setIsQ7Collapsed(!isQ7Collapsed)}
                variant="faq"
                plusVariant="thick"
            >
                <span>
                    <p>
                        If you&apos;re deciding on the cover you&apos;d like to get for your home,
                        it&apos;s important to know the difference between a boiler cover and
                        central heating cover and home cover.
                    </p>
                    <p>
                        The Your Boiler plan covers your boiler and controls, while the Your Heating
                        plan is an upgrade from the Your Boiler plan, and protects your entire
                        central heating system. With this plan we&apos;ll safeguard your radiators,
                        valves and cylinders to make sure you don&apos;t go without heating, no
                        matter the time of the year. The Your Home plan includes everything in the
                        other two plans, plus plumbing, drains and home electrics. You&apos;ll have
                        additional cover incase of burst pipes, broken toilets, blocked drains,
                        faulty fuse boxes and more.*
                    </p>
                    <p>
                        If you&apos;re a landlord, find out what you get with each landlord policy
                        on the landlord plans page{' '}
                        <a
                            href="https://hometree.comparethemarket.com/products/compare-the-market-landlord?package=12&contribution=95&billing=monthly&customerType=landlord"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        .
                    </p>
                    <p>*terms and conditions apply</p>
                </span>
            </Collapsible>

            <a href="https://www.hometree.co.uk/help-centre/">
                <Button variant="dark">View all FAQs</Button>
            </a>
        </div>
    )
}

export default FAQs
