import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import CheckoutForm from 'features/form/checkout'
import OrderForm from 'features/form/order'
import styles from './index.module.scss'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || '',
}

function Checkout(): JSX.Element {
    useEffect(() => {
        if (tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs)
    }, [])

    return (
        <>
            <div className={styles.checkoutContainer}>
                <div className={styles.orderForm}>
                    <OrderForm />
                </div>
                <div className={styles.checkoutForm}>
                    <CheckoutForm />
                </div>
            </div>
        </>
    )
}
export default Checkout
