import { JSONSchemaType } from 'ajv'

export interface GetCatalogueResponse {
    data: CatalogueEntry[]
}

export interface CatalogueEntry {
    pricebook_id: number
    name: string
    landlord_addition: number
    annual_multiplier: number
    pricebook_entries: PricebookEntry[]
}

export interface PricebookEntry {
    entry_id: number
    price_oneoff?: number | null
    price_nocontribution: number
    price_60contribution: number
    price_95contribution: number
    price?: number | null
    contribution?: number | null
    package: Package
}

export interface Package {
    name: string
    type: 'Homeowner' | 'Landlord'
    insurance: 0 | 1
    products: Product[]
    package_id: number
}

export interface Product {
    name: string
    featured: 0 | 1
    included: 0 | 1
    product_id: number
    description: string
    landlord_only: 0 | 1
    limit_per_contract: number
    position: number
}

export const productSchema: JSONSchemaType<Product> = {
    type: 'object',
    properties: {
        name: { type: 'string' },
        featured: { type: 'number', enum: [0, 1] },
        included: { type: 'number', enum: [0, 1] },
        product_id: { type: 'number' },
        description: { type: 'string' },
        landlord_only: { type: 'number', enum: [0, 1] },
        limit_per_contract: { type: 'number' },
        position: { type: 'number' },
    },
    required: [
        'name',
        'featured',
        'included',
        'product_id',
        'description',
        'landlord_only',
        'limit_per_contract',
        'position',
    ],
}

export const packageSchema: JSONSchemaType<Package> = {
    type: 'object',
    properties: {
        name: { type: 'string' },
        type: { type: 'string', enum: ['Homeowner', 'Landlord'] },
        insurance: { type: 'number', enum: [0, 1] },
        products: {
            type: 'array',
            items: productSchema,
        },
        package_id: { type: 'number' },
    },
    required: ['name', 'type', 'insurance', 'products', 'package_id'],
}

export const pricebookEntrySchema: JSONSchemaType<PricebookEntry> = {
    type: 'object',
    properties: {
        entry_id: { type: 'number' },
        price_oneoff: { type: 'number', nullable: true },
        price_nocontribution: { type: 'number' },
        price_60contribution: { type: 'number' },
        price_95contribution: { type: 'number' },
        price: { type: 'number', nullable: true },
        contribution: { type: 'number', nullable: true },
        package: packageSchema,
    },
    required: [
        'entry_id',
        'price_nocontribution',
        'price_60contribution',
        'price_95contribution',
        'package',
    ],
}

export const catalogueEntrySchema: JSONSchemaType<CatalogueEntry> = {
    type: 'object',
    properties: {
        pricebook_id: { type: 'number' },
        name: { type: 'string' },
        landlord_addition: { type: 'number' },
        annual_multiplier: { type: 'number' },
        pricebook_entries: {
            type: 'array',
            items: pricebookEntrySchema,
        },
    },
    required: [
        'pricebook_id',
        'name',
        'landlord_addition',
        'annual_multiplier',
        'pricebook_entries',
    ],
}

export const getCatalogueResponseSchema: JSONSchemaType<GetCatalogueResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: catalogueEntrySchema,
        },
    },
    required: ['data'],
}
