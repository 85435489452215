import { ChangeEvent, useState } from 'react'
import styles from './index.module.scss'

interface FormDatePickerProps {
    id: string
    header?: string
    value?: Date
    minDate?: Date
    maxDate?: Date
    // eslint-disable-next-line no-unused-vars
    onChange: (date: Date) => void
    error?: string
    dataTestId?: string
}

const DEFAULT_DAYS_LIMIT = 180
const DAY_IN_MILLIS = 24 * 60 * 60 * 1000
const MINUTE_IN_MILLIS = 60 * 1000

function FormDatePicker({
    id,
    header,
    value,
    minDate,
    maxDate,
    onChange,
    error,
    dataTestId,
}: FormDatePickerProps): JSX.Element {
    const today = new Date(Date.now())

    const [selectedDate, setSelectedDate] = useState<Date>(value || today)

    // TODO remove temporary debug code once error is resolved in Sentry.
    // eg https://sentry.io/organizations/hometree/issues/2982310457/?project=5864166&referrer=slack
    const formatDate = (date: Date, source?: string) => {
        try {
            return new Date(date.getTime() - date.getTimezoneOffset() * MINUTE_IN_MILLIS)
                .toISOString()
                .split('T')[0]
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('An error occured formatting dates in the DatePicker', {
                date,
                fn: 'formatDate',
                source,
                error: JSON.stringify(e),
            })
            throw e
        }
    }

    const onSelectedDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) return

        const date = new Date(event.target.value)
        setSelectedDate(date)
        onChange(date)
    }

    return (
        <div className={`${styles.datepickerContainer} ${!!error && styles.error}`}>
            {header && <label htmlFor={id}>{header}</label>}
            <input
                id={id}
                data-testid={dataTestId}
                type="date"
                onChange={onSelectedDateChange}
                value={formatDate(selectedDate, 'value')}
                min={minDate ? formatDate(minDate, 'min minDate') : formatDate(today, 'min today')}
                max={
                    maxDate
                        ? formatDate(maxDate, 'max maxDate')
                        : formatDate(
                              new Date(today.getTime() + DEFAULT_DAYS_LIMIT * DAY_IN_MILLIS),
                              'max today'
                          )
                }
            />
            {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
    )
}

export default FormDatePicker
