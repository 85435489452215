import { PricebookEntry } from '../types/catalogue'
import { BillingType } from '../types/formData'

export const calculateMonthlyPackagePrice = (
    contribution: number,
    entry: PricebookEntry
): number => {
    const mapping: { [key: number]: number } = {
        0: entry.price_nocontribution,
        60: entry.price_60contribution,
        95: entry.price_95contribution,
    }

    if (!(contribution in mapping)) {
        return entry.price_nocontribution
    }
    return mapping[contribution]
}

export const calculateMonthlyTotalPrice = (
    entry: PricebookEntry,
    contribution: number,
    addOnPrice: number
): number => {
    const packagePrice = calculateMonthlyPackagePrice(contribution, entry)

    return packagePrice + addOnPrice
}

export const toBillingPrice = (amount: number, billingOption: string): number =>
    billingOption.toLowerCase() === BillingType.ANNUAL ? amount * 12 : amount
