/* eslint-disable no-console */
import {
    Callbacks,
    ChargebeeInstance,
    InitOptions,
    PaymentInfo,
    PaymentIntent,
} from '@chargebee/chargebee-js-types'
import AdyenCheckout from '@adyen/adyen-web'

import '@adyen/adyen-web/dist/adyen.css'
import { useCheckoutContext } from 'contexts/checkout'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { chargebeeGeneratePaymentIntent } from 'api/chargebeeGeneratePaymentIntent'
import Core from '@adyen/adyen-web/dist/types/core/core'
import Modal from 'components/modal'
import Button from 'components/button'
import { ReactComponent as HometreeLogo } from 'assets/logos/mable-hometree.svg'
import { ReactComponent as AdyenLogo } from 'assets/logos/adyen.svg'
import { getMandatoryEnvVar } from 'utils/env'
import { useNavigate } from 'react-router-dom'
import { adyenListPaymentMethods } from 'api/adyenListPaymentMethods'
import styles from './index.module.scss'
import BillingAddressForm from './billingAddressForm'
import ErrorScreen from './errorScreen'
import { handleChargebeeSuccess } from '../handleThirdPartyCallbacks'

const CHARGEBEE_SITE = getMandatoryEnvVar('REACT_APP_CHARGEBEE_SITE')
const CHARGEBEE_PUBLISHABLE_KEY = getMandatoryEnvVar('REACT_APP_CHARGEBEE_PUBLISHABLE_KEY')
const ADYEN_CLIENT_KEY = getMandatoryEnvVar('REACT_APP_ADYEN_CLIENT_KEY')
const ADYEN_ENV = getMandatoryEnvVar('REACT_APP_ADYEN_ENV')

declare global {
    interface Window {
        // AdyenCheckout: any
        Chargebee: {
            init(op: InitOptions): void
            getInstance(): ChargebeeInstance
        }
    }
}

let adyenCardElement: any = null
let adyenCheckout: Core | null = null

export interface ChargebeeCardComponentRef {
    handleChargebeeModalOpen: () => void
}

const ChargebeeCardComponent = forwardRef<ChargebeeCardComponentRef>((_, ref) => {
    const { state, dispatch } = useCheckoutContext()
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const [showLoadingModal, setLoadingModal] = useState(false)
    const [cardError, setCardError] = useState({
        title: '',
        message: '',
    })
    const [cardIsValid, setCardIsValid] = useState(true)
    const [isBillingAddressValid, setIsBillingAddressValid] = useState(true)
    const [billingAddressState, setBillingAddressState] = useState({
        country: 'United Kingdom',
        firstName: state.firstName || '',
        lastName: state.lastName || '',
        phoneNumber: state.phone || '',
        line1: state.street || '',
        line2: '',
        town: state.town || '',
        county: state.county || '',
        postcode: state.postcode || '',
    })

    const handleOnCardSubmit = () => {
        if (!cardIsValid || !isBillingAddressValid) {
            console.error('Invalid card or billing address when submitting card form')
            return
        }

        setLoadingModal(true)

        const chargebeeInstance = window.Chargebee.getInstance()

        chargebeeInstance.load3DSHandler().then((threeDSHandler) => {
            chargebeeGeneratePaymentIntent(state).then((response) => {
                threeDSHandler.setPaymentIntent(response, {
                    adyen: adyenCheckout as object,
                } as any)

                const paymentInfo: PaymentInfo = {
                    element: adyenCardElement,
                    additionalData: {
                        billingAddress: {
                            firstName: billingAddressState.firstName,
                            lastName: billingAddressState.lastName,
                            phone: billingAddressState.phoneNumber,
                            addressLine1: billingAddressState.line1,
                            addressLine2: billingAddressState.line2,
                            city: billingAddressState.town,
                            state: billingAddressState.county,
                            // stateCode: 'CA', // TODO do we need this for UK?
                            countryCode: 'GB',
                            zip: billingAddressState.postcode,
                        },
                    },
                }

                const threeDSCallbacks: Callbacks = {
                    success: (intent: PaymentIntent) => {
                        // Triggers when card is 3DS authorized
                        handleChargebeeSuccess(intent, dispatch, navigate)
                    },
                    error: (_intent: PaymentIntent, error: any) => {
                        // Triggers when 3DS authorization fails
                        setLoadingModal(false)
                        setCardError({
                            title: '3D authentication failed',
                            message: `Reason: ${error.message}`,
                        })
                    },
                }

                threeDSHandler.handleCardPayment(paymentInfo, threeDSCallbacks).catch((error) => {
                    setLoadingModal(false)
                    setCardError({
                        title: '3D authentication failed',
                        message: `Reason: ${error.message}`,
                    })
                })
            })
        })
    }

    const handleOnCardChange = (adyenState: { isValid: boolean; data: any }) => {
        // adyenState.isValid // True or false. Specifies if all the information that the shopper provided is valid.
        // adyenState.data // Provides the data that you need to pass in the `/payments` call.
        // component // Provides the active component instance that called this event.
        setCardIsValid(adyenState.isValid)
    }

    const loadAdyenCardComponent = async () => {
        if (!state.packageId) {
            // User shouldn't get this far without a packageId but TS
            console.error('No packageId in state when loading adyen card component')
            setCardError({
                title: 'Error occured',
                message: `An error occured loading the card payment section.`,
            })
            setLoadingModal(false)
            return
        }

        try {
            const paymentMethodsResponse = await adyenListPaymentMethods(state.packageId)

            if (adyenCheckout == null) {
                AdyenCheckout({
                    locale: 'en_GB',
                    environment: ADYEN_ENV,
                    clientKey: ADYEN_CLIENT_KEY,
                    paymentMethodsResponse,
                    onChange: handleOnCardChange,
                    showPayButton: false,
                }).then((adyenCheckoutArg) => {
                    adyenCheckout = adyenCheckoutArg
                    adyenCardElement = adyenCheckout.create('card', {
                        hasHolderName: true,
                        holderNameRequired: true,
                        positionHolderNameOnTop: true,
                    })
                    adyenCardElement.mount('#card-container')
                    setLoadingModal(false)
                })
            } else {
                adyenCardElement.mount('#card-container')
                setLoadingModal(false)
            }
        } catch (error) {
            console.error('Error occured when loading adyen card component', error)

            setCardError({
                title: 'Error occured',
                message: `An error occured loading the card payment section.`,
            })
            setLoadingModal(false)
        }
    }

    useEffect(() => {
        // Initialise Chargebee
        window.Chargebee.init({
            site: CHARGEBEE_SITE,
            publishableKey: CHARGEBEE_PUBLISHABLE_KEY,
        })
    }, [])

    const openCardComponent = () => {
        setOpenModal(true)
        setLoadingModal(true)

        setBillingAddressState({
            country: 'United Kingdom',
            firstName: state.firstName || '',
            lastName: state.lastName || '',
            phoneNumber: state.phone || '',
            line1: state.street || '',
            line2: '',
            town: state.town || '',
            county: state.county || '',
            postcode: state.postcode || '',
        })

        loadAdyenCardComponent()
    }

    useImperativeHandle(ref, () => ({
        handleChargebeeModalOpen: openCardComponent,
    }))

    const handleBillingAddressChange = (key: string, value: string) => {
        setBillingAddressState({
            ...billingAddressState,
            [key]: value,
        })
    }

    const handleTryAgainErrorClick = () => {
        setCardError({
            title: '',
            message: '',
        })
        setLoadingModal(true)
        loadAdyenCardComponent()
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setCardError({
            title: '',
            message: '',
        })
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                className={styles.cardModal}
                showClose={false}
                showLoading={showLoadingModal}
                onClose={handleCloseModal}
                testId="cardPaymentModal"
            >
                {cardError.title === '' ? (
                    <>
                        <div className={styles.logo}>
                            <HometreeLogo />
                        </div>
                        <hr />
                        <h2>Make a card payment</h2>
                        <h3>Your card details</h3>
                        <div id="card-container" />

                        <BillingAddressForm
                            state={billingAddressState}
                            onChange={handleBillingAddressChange}
                            setBillingAddressState={setBillingAddressState}
                            setIsBillingAddressValid={setIsBillingAddressValid}
                        />

                        <div className={styles.submitButtonContainer}>
                            <Button
                                id="HomeCareCover_Checkout_Chargebee_Submit"
                                onClick={handleOnCardSubmit}
                                className={styles.submitButton}
                                disabled={!cardIsValid || !isBillingAddressValid}
                            >
                                Make payment
                            </Button>
                        </div>
                        <div className={styles.securePaymentText}>
                            <span>All payments are secured and encrypted. Powered by</span>{' '}
                            <AdyenLogo />
                        </div>
                    </>
                ) : (
                    <ErrorScreen error={cardError} handleTryAgainClick={handleTryAgainErrorClick} />
                )}
            </Modal>
        </>
    )
})

export default ChargebeeCardComponent
