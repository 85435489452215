/* eslint-disable no-useless-escape */
export const POSTCODE_REGEX =
    '^([A-PR-UWYZ0-9a-pr-uwyz][A-HK-Y0-9a-hk-y][AEFGHJMNPRTUVXY0-9aeghjmnprtuvxy]?[ABEHMNPRUVWXY0-9abehmnprvwxy]?\\s?[0-9][ABD-HJLN-UW-Zabd-hjln-uw-z]{2}|GIR 0AA|gir 0aa)$'
export const PHONE_NUMBER_REGEX = '^0(?:\\d\\s*){9,10}$'
// export const PHONE_NUMBER_REGEX =
//     '^(((\\+44\\s?[0-9]{4}|\\(?0[0-9]{4}\\)?)\\s?[0-9]{3}\\s?[0-9]{3})|((\\+44\\s?[0-9]{3}|\\(?0[0-9]{3}\\)?)\\s?[0-9]{3}\\s?[0-9]{4})|((\\+44\\s?[0-9]{2}|\\(?0[0-9]{2}\\)?)\\s?[0-9]{4}\\s?[0-9]{4}))\\s*$'
export const EMAIL_REGEX =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
export const TEXT_REGEX = "^[A-Za-z\\s\\-\\.']+$"
export const ALPHA_NUM_EXTENDED = "^[a-zA-Z\\d\\-_,/\\s\\.']+$"

export const PHONE_NUMBER = '0330 912 4843'
export const PHONE_NUMBER_LINK = 'tel:+443309124843'

export const INTEGRATION_ENDPOINT = 'integration'
export const CONTRACTS_ENDPOINT = 'contracts-public'
export const PROPERTIES_ENDPOINT = 'properties-public'
export const PROMOCODES_ENDPOINT = 'promocodes-public'
export const POSTCODES_ENDPOINT = 'postcodes'
export const ACCOUNTS_ENDPOINT = 'accounts-public'
