import { useContext } from 'react'
import { isCTMHost } from 'utils/partners'
import CtmNavBar from 'features/ctm/components/navbar'
import { PromoContext } from 'features/promoMode'
import PromoBanner from './components/promoBanner'
import NavBar from './components/navbar'

function Header(): JSX.Element {
    const { phase: promoPhase } = useContext(PromoContext)

    return (
        <>
            {promoPhase ? <PromoBanner phase={promoPhase} /> : <></>}

            {isCTMHost() ? (
                <CtmNavBar countdown={!!promoPhase?.countdown} showPromoBanner={!!promoPhase} />
            ) : (
                <NavBar countdown={!!promoPhase?.countdown} showPromoBanner={!!promoPhase} />
            )}
        </>
    )
}

export default Header
