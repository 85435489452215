import { ReactComponent as TickLogo } from 'assets/checkboxTick.svg'
import styles from './index.module.scss'

interface Props {
    id: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
    name?: string
    children: JSX.Element | string
}

function Checkbox({ id, onChange, checked, name, children }: Props) {
    return (
        <div className={styles.checkboxContainer}>
            <label>
                <div>
                    <input
                        type="checkbox"
                        name={name || ''}
                        onChange={onChange}
                        id={id}
                        checked={checked}
                    />
                    <span>
                        <TickLogo />
                    </span>
                </div>

                <div className={styles.checkboxChildren}>{children}</div>
            </label>
        </div>
    )
}

export default Checkbox
