import Ajv from 'ajv'
import { CTM_PACKAGES } from 'utils/packages'
import { CatalogueEntry, PricebookEntry, getCatalogueResponseSchema } from '../../types/catalogue'

export const emptyPrices: CtmPrices = {
    1: { price: { 0: 0, 60: 0, 95: 0 } },
    2: { price: { 0: 0, 60: 0, 95: 0 } },
    3: { price: { 0: 0, 60: 0, 95: 0 } },
    4: { price: { 0: 0, 60: 0, 95: 0 } },
    5: { price: { 0: 0, 60: 0, 95: 0 } },
    6: { price: { 0: 0, 60: 0, 95: 0 } },
    10: { price: { 0: 0, 60: 0, 95: 0 } },
    11: { price: { 0: 0, 60: 0, 95: 0 } },
    12: { price: { 0: 0, 60: 0, 95: 0 } },
}

export type CtmPrices = {
    [key in (typeof CTM_PACKAGES)[number]]: {
        price: { 0: number | null; 60: number | null; 95: number | null }
    }
}

export const getCtmPrices = (pricebookEntries: PricebookEntry[]): CtmPrices => {
    const ctmPrices: CtmPrices = {}

    new Set(pricebookEntries.map((entry: PricebookEntry) => entry.package.package_id)).forEach(
        (packageId: number) => {
            ctmPrices[packageId] = {
                price: {
                    0:
                        pricebookEntries.find(
                            (entry) =>
                                entry.package.package_id === packageId && entry.contribution === 0
                        )?.price || null,
                    60:
                        pricebookEntries.find(
                            (entry) =>
                                entry.package.package_id === packageId && entry.contribution === 60
                        )?.price || null,
                    95:
                        pricebookEntries.find(
                            (entry) =>
                                entry.package.package_id === packageId && entry.contribution === 95
                        )?.price || null,
                },
            }
        }
    )

    return ctmPrices
}

export const getPrices = async (pricebookId: number): Promise<CtmPrices> => {
    const getCatalogueResponse = await fetch(
        `${process.env.REACT_APP_PANDORA_API_URL}/pricebooks/model/pricebooks/catalogue/${pricebookId}`,
        {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
        }
    )
    const response = await getCatalogueResponse.json()

    const ajv = new Ajv({ coerceTypes: true, allErrors: true })
    const validator = ajv.compile(getCatalogueResponseSchema)
    const valid = validator(response)

    if (!valid) {
        // eslint-disable-next-line no-console
        console.error('AJV error', validator.errors)
        throw new Error('AJV error')
    }

    const foundPricebook = response.data.find(
        (entry: CatalogueEntry) => entry.pricebook_id === pricebookId
    )

    if (!foundPricebook) {
        throw new Error()
    }

    return getCtmPrices(foundPricebook.pricebook_entries)
}
