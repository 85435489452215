import React, { useEffect } from 'react'
import styles from './index.module.scss'

function ReviewCollector(): JSX.Element {
    const ref = React.useRef(null)

    useEffect(() => {
        const script = document.createElement('script')

        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        script.async = true

        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true)
        }
    }, [window.Trustpilot])
    return (
        <div
            className={styles.reviewCollectorContainer}
            ref={ref}
            data-businessunit-id="57862f270000ff0005924795"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-locale="en-GB"
            data-style-width="100%"
        >
            <a
                href="https://www.trustpilot.com/review/hometree.co.uk"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    )
}

export default ReviewCollector
