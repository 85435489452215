import { useEffect, useRef } from 'react'

function MicroStar() {
    const truspilotRef = useRef(null)

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(truspilotRef.current, true)
        }
    }, [])

    return (
        <div
            ref={truspilotRef}
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5419b732fbfb950b10de65e5"
            data-businessunit-id="57862f270000ff0005924795"
            data-style-height="20px"
            data-stars="4,5"
            data-theme="light"
        >
            <a
                href="https://www.trustpilot.com/review/hometree.co.uk"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    )
}

export default MicroStar
