const formatName = (name: string) => {
    return name
        .split(' ')
        .filter((word) => word.toLowerCase() !== 'oil')
        .join(' ')
        .split('-')[0]
        .trim()
}

export default formatName
