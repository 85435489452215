import axios from 'axios'
import { CONTRACTS_ENDPOINT } from 'config/constants'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL

interface CalculateNextASVDateResponse {
    nextServiceMonth: string
}

export const calculateNextASVDate = async (
    startDate: Date | undefined,
    packageId: number | null = null
) => {
    const isoDate = startDate?.toISOString()

    const params: { packageId?: number } = {}

    if (packageId) {
        params.packageId = packageId
    }

    const responseTest = await axios.get<CalculateNextASVDateResponse>(
        `${API_BASE_URL}/${CONTRACTS_ENDPOINT}/pub/v1/annual-service/next/${isoDate}`,
        {
            params,
        }
    )

    return responseTest.data.nextServiceMonth
}
