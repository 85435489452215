import { useEffect, useState } from 'react'
import { useCheckoutContext } from 'contexts/checkout'
import ReviewCollector from 'components/trustPilotReviewCollector'
import BubbleHeading from 'components/bubbleHeading'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { sendPageLandAnalytics } from 'features/analytics'
import styles from './index.module.scss'

function Feedback(): JSX.Element {
    const { state } = useCheckoutContext()
    const [label, setLabel] = useState<string>('Copy')

    useEffect(() => {
        sendPageLandAnalytics('ThankYou', 'PaymentPage')
    }, [])

    const handleCopyButton = () => {
        if (!state.referralLink) return
        navigator.clipboard.writeText(state.referralLink)
        setLabel('Copied')
        setTimeout(() => setLabel('Copy'), 1500)
    }
    const handleShareButton = () => {
        navigator.share({
            url: state.referralLink,
            text: `I've just taken out a plan with Hometree. They're fantastic! Sign-up now using my referral link and get a £50 Amazon.co.uk gift card when you take out a plan`,
        })
    }
    return (
        <>
            <BubbleHeading className={styles.desktopHeading}>Thank You!</BubbleHeading>
            <div className={styles.mobileHeading}>
                <h1>Thank You!</h1>
            </div>
            <section className={styles.form}>
                <h2 className={styles.title}>What&apos;s next</h2>
                <p className={styles.whatsNextText}>
                    Your cover starts on the day you selected at checkout. In the meantime; if you
                    have any questions on anything head over to our{' '}
                    <a
                        href="https://www.hometree.co.uk/help-centre/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Help Center
                    </a>
                </p>
                {state.referralLink ? (
                    <>
                        <h2>Refer a friend for a £50 Amazon.co.uk gift card</h2>
                        <p>
                            Refer a friend or family member and get £50 to spend at Amazon.co.uk.
                            For every person you send our way, we&apos;ll give you (and them) a £50
                            Amazon.co.uk gift card (see referral program T&Cs{' '}
                            <a
                                href="https://www.hometree.co.uk/wp-content/uploads/2022/07/Customer_referral_TCs.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </a>
                            )
                        </p>
                        <h3>Your Referral Link</h3>
                        <div className={styles.linkSection}>
                            <a
                                className={styles.refLink}
                                href={state.referralLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {state.referralLink}
                            </a>
                            <button
                                type="button"
                                className={styles.copyBtn}
                                onClick={() => handleCopyButton()}
                            >
                                {label}
                            </button>
                            <button
                                type="button"
                                className={styles.shareBtn}
                                onClick={() => handleShareButton()}
                            >
                                <div>Share</div>
                                <Share />
                            </button>
                        </div>
                    </>
                ) : null}
                <div className={styles.reviewSection}>
                    <h2>Leave us a review on TrustPilot</h2>
                    <p>
                        We would love to know how you found your experience with Hometree today by
                        leaving us a review on TrustPilot. Every review goes a long way in helping
                        others like you make the right decision!
                    </p>
                    <ReviewCollector />
                </div>
            </section>
        </>
    )
}

export default Feedback
