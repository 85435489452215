import { verifyReferralcode } from 'api/integration'
import { useQuery } from '@tanstack/react-query'

const validate = (isInitialLoading: boolean, firstName?: string) => {
    if (isInitialLoading) {
        return { isReferralError: false, isReferralValid: false, referralMessage: '' }
    }

    if (!firstName) {
        return {
            isReferralError: true,
            isReferralValid: false,
            referralMessage:
                'Sorry that is an invalid code; please check for any typos or call us at 0330 912 4843',
        }
    }

    return {
        isReferralError: false,
        isReferralValid: true,
        referralMessage: `Nice one! You’re being referred by ${firstName}`,
    }
}

export const useReferralCode = (referralCode: string, email: string | undefined) => {
    const { refetch } = useQuery(['referralVerify', { referralCode, email }], verifyReferralcode, {
        enabled: false,
    })

    const refetchReferralCode = async () => {
        const { data, isInitialLoading } = await refetch()
        if (data?.code === 'disallowedReferralCode') {
            return {
                isReferralError: true,
                isReferralValid: false,
                referralMessage: 'Sorry you cannot use your own referral code',
            }
        }
        return validate(isInitialLoading, data?.first_name)
    }

    return {
        refetchReferralCode,
    }
}
