import React, { createContext, useContext, useReducer } from 'react'
import { updateCookieData } from 'utils/updateCookieData'
import { CookieDataType } from 'types/formData'
import Cookies from 'js-cookie'
import { createLead } from 'api/lead'
import { getCookieDomain } from 'utils/getCookieDomain'
import { generateDefaultState } from './defaultState'
import { loadFromCookie, loadMarketingHashFromCookie } from './loadFromCookie'
import { initialiseCookie } from './initialiseCookie'
import { CheckoutReducerAction, CheckoutState } from './type'

const CheckoutContext = createContext<
    | {
          state: CheckoutState
          dispatch: React.Dispatch<CheckoutReducerAction>
      }
    | undefined
>(undefined)

let createLeadTimeout: NodeJS.Timeout

export const CheckoutReducer = (state: CheckoutState, action: CheckoutReducerAction) => {
    switch (action.type) {
        case 'setCheckout': {
            const cookieData: CookieDataType = JSON.parse(Cookies.get('incoming_data') || '{}')
            const newCookieData = updateCookieData(cookieData, action)
            Cookies.set('incoming_data', JSON.stringify(newCookieData), {
                domain: getCookieDomain(),
            })

            try {
                clearTimeout(createLeadTimeout)
                createLeadTimeout = setTimeout(() => {
                    createLead({ ...state, ...action.data })
                }, 3000)
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Error happened writing lead to db')
            }
            return { ...state, ...action.data }
        }
        default: {
            throw new Error(`Unkown action type: ${action.type}`)
        }
    }
}

interface CheckoutProviderProps {
    children: JSX.Element | JSX.Element[]
    defaultInitialState?: CheckoutState
}
export function CheckoutProvider({
    children,
    defaultInitialState = generateDefaultState(),
}: CheckoutProviderProps): JSX.Element {
    initialiseCookie('incoming_data')
    const cookieData = loadFromCookie()
    const marketingCookieData = loadMarketingHashFromCookie()
    const initialState = { ...defaultInitialState, ...cookieData, ...marketingCookieData }
    const [state, dispatch] = useReducer(CheckoutReducer, initialState)

    const value = { state, dispatch }

    return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>
}

export const useCheckoutContext = (): {
    state: CheckoutState
    dispatch: React.Dispatch<CheckoutReducerAction>
} => {
    const context = useContext(CheckoutContext)

    if (context === undefined) throw new Error('useCheckout must be used within a provider')

    return context
}

export const fetchLatestCheckoutState = async (): Promise<CheckoutState> => {
    const latestState = {
        ...generateDefaultState(),
        ...loadFromCookie(),
        ...loadMarketingHashFromCookie(),
    }
    return latestState
}
