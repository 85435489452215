import { ReactComponent as Alpha } from 'assets/ctm/brands/alpha.svg'
import { ReactComponent as Baxi } from 'assets/ctm/brands/baxi.svg'
import { ReactComponent as Daikin } from 'assets/ctm/brands/daikin.svg'
import { ReactComponent as Vaillant } from 'assets/ctm/brands/vaillant.svg'
import { ReactComponent as Veissmann } from 'assets/ctm/brands/veissmann.svg'
import { ReactComponent as Worcester } from 'assets/ctm/brands/worcester.svg'
import styles from './index.module.scss'

function BoilerBrands() {
    return (
        <div className={styles.container}>
            <h2>We Cover All Gas Boilers</h2>
            <p>All makes and models included, regardless of age - that’s the deal.</p>
            <div className={styles.slider}>
                <div className={styles.slideTrack}>
                    <div className={styles.slide}>
                        <Alpha />
                    </div>
                    <div className={styles.slide}>
                        <Baxi />
                    </div>
                    <div className={styles.slide}>
                        <Daikin />
                    </div>
                    <div className={styles.slide}>
                        <Vaillant />
                    </div>
                    <div className={styles.slide}>
                        <Veissmann />
                    </div>
                    <div className={styles.slide}>
                        <Worcester />
                    </div>
                    <div className={styles.slide}>
                        <Alpha />
                    </div>
                    <div className={styles.slide}>
                        <Baxi />
                    </div>
                    <div className={styles.slide}>
                        <Daikin />
                    </div>
                    <div className={styles.slide}>
                        <Vaillant />
                    </div>
                    <div className={styles.slide}>
                        <Veissmann />
                    </div>
                    <div className={styles.slide}>
                        <Worcester />
                    </div>
                    <div className={styles.slide}>
                        <Alpha />
                    </div>
                    <div className={styles.slide}>
                        <Baxi />
                    </div>
                    <div className={styles.slide}>
                        <Daikin />
                    </div>
                    <div className={styles.slide}>
                        <Vaillant />
                    </div>
                    <div className={styles.slide}>
                        <Veissmann />
                    </div>
                    <div className={styles.slide}>
                        <Worcester />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoilerBrands
