import { INTEGRATION_ENDPOINT } from 'config/constants'
import { CheckoutState } from 'contexts/checkout/type'
import { dateToString } from 'utils/dateToString'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL

interface RequestPayload {
    first_name: string
    last_name: string
    email: string
    phone1_prefix: string
    phone1: string
    address_line_1: string
    postcode: string
    town: string
    country: string
    start_date: string
    description: string
    package_id: number | null
    redirect: string
}

export type GCDropinOptions = {
    flowID: string
    environment: string
} | null

export const generateGCFlow = async (state: CheckoutState) => {
    const input: RequestPayload = {
        first_name: state.firstName || '',
        last_name: state.lastName || '',
        email: state.email || '',
        phone1_prefix: state.phonePrefix || '',
        phone1: state.phone || '',
        address_line_1: state.street || '',
        postcode: state.postcode || '',
        town: state.town || '',
        country: state.country || '',
        start_date: state.date ? dateToString(state.date) : '',
        description: state.gcDescription || '',
        package_id: state.packageId || null,
        redirect: window.location.href,
    }

    const response = await fetch(`${API_BASE_URL}/${INTEGRATION_ENDPOINT}/gocardless/flow`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(input),
    })

    const {
        data: { flowID, environment },
    } = (await response.json()) as { data: { flowID: string; environment: string } }

    return { flowID, environment }
}
