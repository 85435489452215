import Button from 'components/button'
import Modal from 'components/modal'
import { useState } from 'react'
import { ReactComponent as HometreeLogo } from 'assets/logos/mable-hometree.svg'
import { ReactComponent as CTMLogo } from 'assets/ctm/partnership-solo.svg'
import MicroStar from '../trustpilot/microStar'
import styles from './index.module.scss'

interface Props {
    countdown: boolean
    showPromoBanner: boolean
}

function CtmNavBar({ countdown, showPromoBanner }: Props): JSX.Element {
    const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false)

    const countdownStyle = countdown ? styles.countdown : styles.noCountdown
    return (
        <>
            <div
                className={`${styles.content} ${styles.navbar} ${
                    showPromoBanner ? countdownStyle : styles.disabled
                }`}
            >
                <div className={`${styles.content}`}>
                    <div className={styles.logos}>
                        <a href="https://www.hometree.co.uk/" aria-label="Go to homepage">
                            <div className={styles.logo}>
                                <HometreeLogo title="Hometree logo" />
                            </div>
                        </a>
                        <span className={`${styles.vr} ${styles.desktopOnly}`} />
                        <span className={`${styles.ctm} ${styles.desktopOnly}`}>
                            <div className={styles.logo}>
                                <CTMLogo />
                            </div>
                        </span>
                    </div>

                    {/* spacer? */}
                    <div />

                    <div className={`${styles.contactUs} ${styles.desktopOnly}`}>
                        <div className={styles.tpContainer}>
                            <MicroStar />
                        </div>
                        Need help? Call&nbsp;&nbsp;
                        <a href="tel:0330 912 4843">0330&nbsp;912&nbsp;4843</a>
                    </div>
                    <div className={styles.mobileOnly}>
                        <Button onClick={() => setIsContactUsModalOpen(true)}>Contact Us</Button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isContactUsModalOpen}
                onClose={() => setIsContactUsModalOpen(false)}
                buttonText="Close"
                className={styles.contactUsModal}
            >
                <h2>Contact Us</h2>
                <a href="tel:0330 912 4843">
                    <Button>Give us a call</Button>
                </a>
                <span>0330 912 4843</span>

                <hr />

                <h3>Phone</h3>
                <p>
                    <strong>Monday to Friday:</strong> 9am - 5pm
                    <br />
                    <strong>Saturday to Sunday:</strong> Closed
                </p>

                <h3>Form</h3>
                <p>
                    Contact us{' '}
                    <a target="_blank" href="https://www.hometree.co.uk/contact/" rel="noreferrer">
                        here
                    </a>
                </p>
            </Modal>
        </>
    )
}

export default CtmNavBar
