// eslint-disable-next-line import/prefer-default-export
export const getPolicyStartDate = (date?: string): Date => {
    const givenDate = new Date(date || '')
    const currentDate = new Date()

    if (givenDate >= currentDate) {
        return givenDate
    }
    return currentDate
}
