import { validatePromoCode, ValidatePromoCodeResponse } from 'api/promoCodes'
import { useCheckoutContext } from 'contexts/checkout'
import { useQuery } from '@tanstack/react-query'
import { PromoCode } from 'types/promocode'
import { penceToPounds } from 'utils/penceToPounds'

const validate = (
    isPromoCodeIdle: boolean,
    packageId?: number,
    promoCodeData?: ValidatePromoCodeResponse['data']
) => {
    if (isPromoCodeIdle) {
        return {
            isPromoCodeError: false,
            isPromoCodeValid: false,
            promoCodeMessage: '',
        }
    }
    if (!promoCodeData?.isValid) {
        return {
            isPromoCodeError: true,
            isPromoCodeValid: false,
            promoCodeMessage:
                'Sorry that is an invalid code; please check for any typos or call us at 0330 912 4843',
        }
    }

    // Confirmed with LOKI, tracking and voucher codes apply to all packages
    if (![PromoCode.PERCENTAGE, PromoCode.VALUE].includes(promoCodeData.type)) {
        return {
            isPromoCodeError: false,
            isPromoCodeValid: true,
            promoCodeMessage: '',
        }
    }
    const hasPackageInPromoCode = promoCodeData.package_to_promocode?.some((data) => {
        return data.package_id === packageId
    })

    if (!hasPackageInPromoCode) {
        return {
            isPromoCodeError: true,
            isPromoCodeValid: false,
            promoCodeMessage: 'Your code is not compatible with this product',
        }
    }

    return {
        isPromoCodeError: false,
        isPromoCodeValid: true,
        promoCodeMessage: '',
    }
}

const processPromoCodeType = (
    monthlyTotalPrice: number,
    promoCodeData: ValidatePromoCodeResponse['data']
) => {
    if (promoCodeData.type === PromoCode.PERCENTAGE) {
        const newMonthlyTotalPrice = monthlyTotalPrice * (promoCodeData.value / 100)
        return {
            isPromoCodeError: false,
            isPromoCodeValid: true,
            promoCodeMessage: `${
                promoCodeData.value
            }% discount applied. Saving you £${penceToPounds(Math.round(newMonthlyTotalPrice))}`,
        }
    }

    if (promoCodeData.type === PromoCode.VALUE) {
        // Value discounts don't mention how much it takes off and applies it annual
        // may mislead monthly payment users
        return {
            isPromoCodeError: false,
            isPromoCodeValid: true,
            promoCodeMessage: `£${promoCodeData.value} discount applied over total amount paid annually.`,
        }
    }

    if (promoCodeData.type === PromoCode.VOUCHER) {
        return {
            isPromoCodeError: false,
            isPromoCodeValid: true,
            promoCodeMessage: `You will receive a £${promoCodeData.value} voucher.`,
        }
    }

    if (promoCodeData.type === PromoCode.TRACKING) {
        return {
            isPromoCodeError: false,
            isPromoCodeValid: true,
            promoCodeMessage: `code added.`,
        }
    }

    return {
        isPromoCodeError: true,
        isPromoCodeValid: false,
        promoCodeMessage: `Unsupported promocode type ${promoCodeData.type}`,
    }
}

type RefetchPromoCodeResult = {
    isPromoCodeError: boolean
    isPromoCodeValid: boolean
    promoCodeMessage: string
    promoCodeData?: ValidatePromoCodeResponse['data']
}

export const usePromoCode = (promoCode: string) => {
    const { state } = useCheckoutContext()

    const { refetch } = useQuery(['promoCode', { promoCode }], validatePromoCode, {
        enabled: false,
    })

    const refetchPromoCode = async (): Promise<RefetchPromoCodeResult> => {
        const { data, isInitialLoading } = await refetch()
        const validateResult = validate(isInitialLoading, state.packageId, data)

        if (!validateResult.isPromoCodeValid || !data) {
            return { ...validateResult, promoCodeData: data }
        }

        return { ...processPromoCodeType(state.monthlyTotalPrice, data), promoCodeData: data }
    }

    return {
        refetchPromoCode,
    }
}
