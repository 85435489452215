import styles from './index.module.scss'

interface DropdownOption {
    value: string | number
    displayValue: string
    disabled?: boolean
    hidden?: boolean
}

interface DropdownProps {
    testId?: string
    header?: string
    selected?: string
    defaultValue?: string
    options: DropdownOption[]
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string) => void
    error?: string
    name?: string
    className?: string
}

function Dropdown({
    testId,
    header,
    selected,
    defaultValue,
    options,
    onChange,
    error,
    name,
    className,
}: DropdownProps): JSX.Element {
    return (
        <div
            className={`${styles.dropdownContainer} ${className || ''} ${!!error && styles.error}`}
        >
            <div>
                {header && <label htmlFor={`select-${testId || 'unknown'}`}>{header}</label>}
                <select
                    id={`select-${testId || 'unknown'}`}
                    data-testid={testId}
                    onChange={(e) => onChange(e.target.value)}
                    value={selected}
                    defaultValue={defaultValue}
                    name={name || ''}
                >
                    {options.map(({ value, displayValue, disabled = false, hidden = false }) => (
                        <option key={value} value={value} disabled={disabled} hidden={hidden}>
                            {displayValue}
                        </option>
                    ))}
                </select>
            </div>
            {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
    )
}

export default Dropdown
