/* istanbul ignore file */
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { initializeMixpanel } from 'utils/mixpanel'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@fontsource/roboto'
import initializeSentry from './utils/sentry'

initializeSentry()
initializeMixpanel()

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || '',
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
