import { useState, useEffect } from 'react'
import { concatStrings } from 'utils/concatStrings'
import { formatPostcode } from 'utils/formatPostcode'
import { isAlphaNumeric, isPostcode } from 'utils/validation'
import { Address } from 'types/address'
import { useCheckoutContext } from 'contexts/checkout'
import { validatePropertyContract } from 'api/validatePropertyContract'
import TextInput from 'components/textInput'
import Button from 'components/button'
import PostcodeFinder from '../../postcodeFinder'
import styles from './index.module.scss'

interface AddressFormSectionProps {
    setAddressSectionValid: (value: boolean) => void
    addressSectionValid: boolean
    onSubmit: () => void
}

function AddressFormSection({
    setAddressSectionValid,
    addressSectionValid,
    onSubmit,
}: AddressFormSectionProps): JSX.Element {
    const { state, dispatch } = useCheckoutContext()

    const [inputErrors, setInputErrors] = useState({
        searched: '',
        street: '',
        town: '',
        county: '',
        postcode: '',
    })

    const [searched, setSearched] = useState<boolean>(false)
    const [hasChosenAddress, setHasChosenAddress] = useState<boolean>(false)
    const [contractExistError, setContractExistError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const inputValidators = {
        street: isAlphaNumeric,
        town: isAlphaNumeric,
        county: isAlphaNumeric,
        postcode: isPostcode,
    }

    const isInputValid = (field: keyof typeof inputValidators, value?: string) => {
        if (typeof value === 'undefined' || (!value && field !== 'county')) {
            return false
        }

        if (!inputValidators[field](value)) {
            return false
        }

        return true
    }

    const onInputBlur = (field: keyof typeof inputValidators) => {
        const errorMessageToField = {
            street: 'Please enter a valid street',
            town: 'Please enter a valid town',
            county: 'Please enter a valid county',
            postcode: 'Please enter a valid postcode',
        }

        if ((state[field] || '') === '' && field !== 'county') {
            setInputErrors((prevState) => ({ ...prevState, [field]: 'This field is required' }))
            return
        }

        if (
            !isInputValid(field, state[field]) &&
            (field !== 'county' || (field === 'county' && state.county !== ''))
        ) {
            setInputErrors((prevState) => ({ ...prevState, [field]: errorMessageToField[field] }))
        } else {
            setInputErrors((prevState) => ({ ...prevState, [field]: '' }))
        }
    }

    const onInputChange = (field: keyof typeof inputValidators, value: string) => {
        if (isInputValid(field, value)) {
            setInputErrors((prevState) => ({ ...prevState, [field]: '' }))
        }

        dispatch({
            type: 'setCheckout',
            data: { [field]: field === 'postcode' ? formatPostcode(value) : value },
        })
    }

    useEffect(() => {
        setAddressSectionValid(false)
        setContractExistError(false)

        if (
            isInputValid('street', state.street) &&
            isInputValid('town', state.town) &&
            (state.county === '' || isInputValid('county', state.county)) &&
            isInputValid('postcode', state.postcode)
        ) {
            setAddressSectionValid(true)
        } else {
            setAddressSectionValid(false)
        }
    }, [state.street, state.town, state.county, state.packageDetails, state.postcode])

    const onSelect = async ({
        line1,
        line2,
        line3,
        line4,
        locality,
        town: selectedTown,
        county: selectedCounty,
        postcode: selectedPostcode,
    }: Address) => {
        setInputErrors((prevState) => ({
            ...prevState,
            searched: '',
            street: '',
            town: '',
            county: '',
        }))

        onInputChange('street', concatStrings([line1, line2, line3, line4, locality]))
        onInputChange('town', selectedTown)
        onInputChange('county', selectedCounty)
        onInputChange('postcode', formatPostcode(selectedPostcode))

        setHasChosenAddress(true)
    }

    const onSearch = () => {
        setInputErrors((prevState) => ({
            ...prevState,
            searched: '',
            street: '',
            town: '',
            county: '',
        }))
        setContractExistError(false)

        dispatch({
            type: 'setCheckout',
            data: {
                street: '',
                town: '',
                county: '',
            },
        })
        setSearched(true)
    }

    const onContinue = async () => {
        setLoading(true)
        if (state.postcode && state.street) {
            const contractExist = await validatePropertyContract(state.postcode, state.street)
            if (contractExist) {
                setContractExistError(true)
                return setLoading(false)
            }
        }
        setLoading(false)

        if (addressSectionValid) {
            onSubmit()
        }

        return null
    }

    return (
        <>
            <p>
                <strong>Covered address</strong>
            </p>
            <p>Please enter the postcode for the property you&apos;d like to protect</p>
            {contractExistError && (
                <p className={styles.error} data-testid="error">
                    A contract for this property already exists. Please call 0330 912 4843 to speak
                    to our team.
                </p>
            )}
            <PostcodeFinder
                onSelect={onSelect}
                onSearch={onSearch}
                postcode={state.postcode || ''}
                setPostcode={(value) => onInputChange('postcode', value)}
            />

            {!!inputErrors.searched && <p>{inputErrors.searched}</p>}

            {searched && (
                <div>
                    <TextInput
                        id="HomeCareCover_Checkout_Address_ChangeAddressStreet"
                        testId="street"
                        placeholder="House number and street"
                        value={state.street || ''}
                        onChange={(value) => onInputChange('street', value)}
                        onBlur={() => onInputBlur('street')}
                        error={inputErrors.street}
                        className="maskpii sessioncamexclude sessioncamhidetext"
                    />
                    <TextInput
                        id="HomeCareCover_Checkout_Address_ChangeAddressTown"
                        testId="town"
                        placeholder="Town/City"
                        value={state.town || ''}
                        onChange={(value) => onInputChange('town', value)}
                        onBlur={() => onInputBlur('town')}
                        error={inputErrors.town}
                        className="maskpii sessioncamexclude sessioncamhidetext"
                    />
                    <TextInput
                        id="HomeCareCover_Checkout_Address_ChangeAddressCountry"
                        testId="county"
                        placeholder="County"
                        value={state.county || ''}
                        onChange={(value) => onInputChange('county', value)}
                        onBlur={() => onInputBlur('county')}
                        error={inputErrors.county}
                        className="maskpii sessioncamexclude sessioncamhidetext"
                    />
                    <TextInput
                        id="HomeCareCover_Checkout_Address_ChangeAddressPostcode"
                        testId="postcode"
                        placeholder="Postcode"
                        value={state.postcode || ''}
                        onChange={(value) => onInputChange('postcode', value)}
                        onBlur={() => onInputBlur('postcode')}
                        error={inputErrors.postcode}
                        className="maskpii sessioncamexclude sessioncamhidetext"
                    />
                </div>
            )}

            <Button
                id="HomeCareCover_Checkout_Address_SubmitSection"
                disabled={!hasChosenAddress || !addressSectionValid || contractExistError}
                onClick={onContinue}
                loading={loading}
            >
                Next
            </Button>
        </>
    )
}

export default AddressFormSection
