import { PromoPhase } from 'features/promoMode/promo-phase.class'
import { format } from 'date-fns'
import { useContext } from 'react'
import { PromoContext } from 'features/promoMode'
import styles from './index.module.scss'

type Props = {
    phase: PromoPhase
}

function NoCountdown({ phase }: Props): JSX.Element {
    const { config: promoConfig } = useContext(PromoContext)

    const dateFormatted = promoConfig?.end ? format(promoConfig.end, 'do MMM yyyy') : null
    const dateFormattedMobile = promoConfig?.end ? format(promoConfig.end, "d MMMM ''yy") : null

    return (
        <>
            <div className={`${styles.mobile} ${styles.countdownRow}`} data-testid="promo-banner">
                <span className={`${styles.countdown} ${styles.countdownFar}`}>
                    {dateFormatted ? (
                        <>
                            Limited Time Offer -&nbsp;<span>Ends {dateFormattedMobile}</span>
                        </>
                    ) : (
                        <>Limited Time Offer</>
                    )}
                </span>
            </div>
            <div className={`${styles.mobile} ${styles.content}`}>
                <h3>{phase.banner.primaryTextMobile}</h3>
                <span className={styles.tc}>{phase.banner.secondaryText}</span>
            </div>

            <div className={`${styles.desktop} ${styles.promoBanner}`} data-testid="promo-banner">
                <div className={styles.content}>
                    <h3>{phase.banner.primaryText}</h3>
                    <span className={styles.tc}>{phase.banner.secondaryText}</span>
                    {dateFormatted ? (
                        <span className={styles.noCountdown}>Ends midnight {dateFormatted}</span>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    )
}

export default NoCountdown
