export const getMandatoryEnvVar = (variable: string) => {
    const result = process.env[variable]
    if (!result) {
        throw new Error(`Missing mandatory environment variable: ${variable}`)
    }
    return result
}

export enum ENVIRONMENT {
    DEV = 'development',
    STAGE = 'stage',
    PROD = 'prod',
}
