import { useEffect } from 'react'
import { isCTMHost } from 'utils/partners'

declare const window: Window & { utag_datalayer: any[]; utag: any }
declare const tracking: { hashString: (email: string) => string }

const commonUtagData = {
    app_name: 'HomeCareCover',
    app_version: '1.0',
    tealium_profile: 'home-care-cover',
    app_platform: '7d2f4297-262e-403c-9f49-c0e9633d5fa4',
}

const getUrlParamByName = (name: string) => {
    const queryParamReader = new URLSearchParams(window.location.search)
    return queryParamReader.has(name) ? queryParamReader.get(name) : undefined
}

export const sendPageLandAnalytics = (pageName: string, pageType: string, product: string = '') => {
    if (isCTMHost()) {
        try {
            const utagData = {
                ...commonUtagData,
                page_name: pageName,
                page_type: pageType,
                ...(product === '' ? {} : { product_detail: product }),
            }

            window.utag_datalayer.push(utagData)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('sendLandingAnalytics error caught', error)
        }

        // ensure utag script is loaded
        const timerId = setInterval(() => {
            if (typeof window.utag !== 'undefined' && typeof window.utag.view === 'function') {
                window.utag.view(window.utag.data)
                clearInterval(timerId)
            }
        }, 1000)
    }
}

export const sendEmailAnalytics = (e: {
    email: string
    selectedProduct: {
        type: string
        name: string | undefined
    }
}) => {
    if (isCTMHost()) {
        try {
            const hashedEmail = e.email ? tracking.hashString(e.email.trim().toLowerCase()) : null
            const utagData = {
                ...commonUtagData,
                campaign_maskcode: getUrlParamByName('AFFCLIE') || '',
                page_name: 'Checkout',
                page_type: 'PaymentPage',
                product_detail: `${e.selectedProduct.type}${e.selectedProduct.name || ''}`,
                customer_id: hashedEmail,
            }
            window.utag_datalayer.push(utagData)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('sendEmailAnalytics error caught', error)
        }
    }
}

export const sendConfirmationAnalytics = () => {
    if (isCTMHost()) {
        try {
            const utagData = {
                ...commonUtagData,
                page_name: 'Confirmation',
                page_type: 'PaymentPage',
            }
            window.utag_datalayer.push(utagData)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('sendConfirmationAnalytics error caught', error)
        }
        window.utag.view(window.utag.data)
    }
}

function Analytics(): JSX.Element {
    const addCtmScriptTag = () => {
        const ctmScript = document.createElement('script')
        ctmScript.src = '//chronicle.comparethemarket.com/ctm-hash.js'
        ctmScript.async = true
        document.body.appendChild(ctmScript)
    }
    function enableTealium() {
        const tealiumStage =
            window.location.hostname === 'hometree.comparethemarket.com' ? 'prod' : 'qa'

        let a: string | HTMLScriptElement =
            `//tags.tiqcdn.com/utag/ctm/home-care-cover/${tealiumStage}/utag.js`
        const b = document
        const c = 'script'
        const d = b.createElement(c)
        d.src = a
        d.type = `text/java${c}`
        d.async = true
        d.id = 'analytics'
        // eslint-disable-next-line prefer-destructuring
        a = b.getElementsByTagName(c)[0]
        a.parentNode!.insertBefore(d, a)

        a.addEventListener('load', () => {
            const timerId = setInterval(() => {
                if (window.utag) {
                    clearInterval(timerId)
                }
            }, 1000)
        })
    }

    useEffect(() => {
        if (isCTMHost()) {
            if (!window.utag_datalayer) {
                window.utag_datalayer = []
            }

            window.utag_datalayer.push = function t(event) {
                const timerId = setInterval(function executeWhenUtagLoaded() {
                    if (window.utag) {
                        Object.assign(window.utag.data, event)
                        clearInterval(timerId)
                    }
                }, 1000)

                // TODO Legacy code from CTM
                // @ts-ignore
                // eslint-disable-next-line prefer-rest-params
                return Array.prototype.push.apply(this, arguments)
            }
            enableTealium()
            addCtmScriptTag()
            sessionStorage.setItem('ctm', 'true')
            const maskcodeValue = getUrlParamByName('AFFCLIE') || ''
            sessionStorage.setItem('maskcode', maskcodeValue)
        }
    }, [])

    return <></>
}

export default Analytics
