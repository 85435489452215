import { ReactComponent as SupportIcon } from 'assets/icons/support.svg'
import { ReactComponent as WorldIcon } from 'assets/icons/world.svg'
import { ReactComponent as BoilerIcon } from 'assets/icons/boiler.svg'
import { ReactComponent as BoilersIcon } from 'assets/icons/boilers.svg'
import styles from './index.module.scss'

function PlanInclusions() {
    return (
        <section className={styles.inclusions}>
            <div className={styles.heading}>
                <h1>Our Care Plans Include</h1>
                <p>Get covered today from just £7.49 per month</p>
            </div>
            <div className={styles.items}>
                <div className={styles.item}>
                    <span className={styles.icon}>
                        <SupportIcon />
                    </span>
                    <div className={styles.text}>
                        <h5>24/7 Claims Support</h5>
                        <span>
                            Access round-the-clock support for claims. Save time by claiming online
                            or giving us a call.
                        </span>
                    </div>
                </div>
                <div className={styles.item}>
                    <span className={styles.icon}>
                        <BoilerIcon />
                    </span>
                    <div className={styles.text}>
                        <h5>Any Gas Boiler, Any Time</h5>
                        <span>
                            Unlike our competitors, we cover all gas boilers regardless of make,
                            model, or age.
                        </span>
                    </div>
                </div>
                <div className={styles.item}>
                    <span className={styles.icon}>
                        <WorldIcon />
                    </span>
                    <div className={styles.text}>
                        <h5>Nationwide Coverage</h5>
                        <span>
                            We offer protection to homes across mainland England, Scotland and
                            Wales.
                        </span>
                    </div>
                </div>
                <div className={styles.item}>
                    <span className={styles.icon}>
                        <BoilersIcon />
                    </span>
                    <div className={styles.text}>
                        <h5>Flexible Plans & Prices</h5>
                        <span>
                            Choose the level of cover and find a plan to match your needs and
                            budget.
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PlanInclusions
