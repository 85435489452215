import { useEffect, useRef } from 'react'
import styles from './index.module.scss'

function TrustPilot() {
    const truspilotCarouselRef = useRef(null)
    const trustpilotMiniCarouselRef = useRef(null)

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(truspilotCarouselRef.current, true)
            window.Trustpilot.loadFromElement(trustpilotMiniCarouselRef.current, true)
        }
    }, [])

    return (
        <div className={styles.container}>
            <h2>Don’t just take our word for it.</h2>

            {/* carousel */}
            <div>
                <div className={`${styles.widget} ${styles.desktop}`}>
                    <div
                        ref={truspilotCarouselRef}
                        className="trustpilot-widget"
                        data-locale="en-GB"
                        data-template-id="53aa8912dec7e10d38f59f36"
                        data-businessunit-id="57862f270000ff0005924795"
                        data-style-height="200px"
                        data-style-width="100%"
                        data-theme="light"
                        data-stars="4,5"
                        data-review-languages="en"
                        data-text-color="#172b02"
                    >
                        <a
                            href="https://uk.trustpilot.com/review/hometree.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Trustpilot
                        </a>
                    </div>
                </div>
            </div>

            {/* mini carousel */}
            <div>
                <div className={`${styles.widget} ${styles.mobile}`}>
                    <div
                        ref={trustpilotMiniCarouselRef}
                        className="trustpilot-widget"
                        data-locale="en-US"
                        data-template-id="539ad0ffdec7e10e686debd7"
                        data-businessunit-id="57862f270000ff0005924795"
                        data-style-height="350px"
                        data-style-width="100%"
                        data-theme="light"
                        data-stars="4,5"
                        data-review-languages="en"
                    >
                        <a
                            href="https://www.trustpilot.com/review/hometree.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Trustpilot
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TrustPilot
