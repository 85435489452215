import { useCheckoutContext } from 'contexts/checkout'
import Checkbox from 'components/checkbox'
import styles from './index.module.scss'

export function MarketingConsent() {
    const { state, dispatch } = useCheckoutContext()

    return (
        <div className={styles.marketingOptIn}>
            <>
                <h3>Keeping in touch</h3>
                <p>
                    Occasionally, we&apos;d like to send you the latest offers and products from
                    Hometree Group, as well as the Hometree referral programme. You can opt-out at
                    any time via our customer portal. Let us know how you would like to keep in
                    touch.
                </p>
            </>
            <span className={styles.checkboxes}>
                <Checkbox
                    id="HomeCareCover_Checkout_Marketing_ChangeEmail"
                    onChange={(e) =>
                        dispatch({
                            type: 'setCheckout',
                            data: {
                                marketingEmail: e.target.checked,
                                referralProgramme: e.target.checked,
                            },
                        })
                    }
                    checked={!!state.marketingEmail}
                >
                    Email
                </Checkbox>
                <Checkbox
                    id="HomeCareCover_Checkout_Marketing_ChangePhone"
                    onChange={(e) =>
                        dispatch({
                            type: 'setCheckout',
                            data: {
                                marketingPhone: e.target.checked,
                                referralProgramme: e.target.checked,
                            },
                        })
                    }
                    checked={!!state.marketingPhone}
                >
                    Phone
                </Checkbox>
                <Checkbox
                    id="HomeCareCover_Checkout_Marketing_ChangeSms"
                    onChange={(e) =>
                        dispatch({
                            type: 'setCheckout',
                            data: {
                                marketingSms: e.target.checked,
                                referralProgramme: e.target.checked,
                            },
                        })
                    }
                    checked={!!state.marketingSms}
                >
                    Text
                </Checkbox>
            </span>
        </div>
    )
}
