import { ReactComponent as PlusIcon } from 'assets/plus.svg'
import { ReactComponent as PlusIconThick } from 'assets/plus-thick.svg'
import { ReactComponent as MinusIconThick } from 'assets/minus-thick.svg'
import styles from './index.module.scss'

interface Props {
    header: JSX.Element | string
    children: JSX.Element
    collapsed: boolean
    onHeaderClick: () => void
    id?: string
    disabled?: boolean
    variant?: 'directDebit' | 'faq' | 'inclusions'
    plusVariant?: 'normal' | 'thick'
}

function Collapsible({
    header,
    children,
    collapsed,
    onHeaderClick,
    disabled,
    id,
    variant = 'directDebit',
    plusVariant = 'normal',
}: Props): JSX.Element {
    const closedIcon =
        plusVariant === 'thick' ? (
            <PlusIconThick className={styles.plusIconThick} />
        ) : (
            <PlusIcon className={styles.plusIcon} />
        )
    const openIcon =
        plusVariant === 'thick' ? (
            <MinusIconThick className={styles.plusIconThick} />
        ) : (
            <PlusIcon className={styles.plusIcon} /> // TODO implement minus icon
        )

    return (
        <div
            className={`${styles.collapsibleContainer} ${collapsed ? styles.collapsed : ''} ${
                disabled ? styles.disabled : ''
            } ${styles[variant]}`}
        >
            <button
                className={styles.collapsibleHeader}
                type="button"
                tabIndex={0}
                onClick={onHeaderClick}
                onKeyDown={onHeaderClick}
                disabled={!!disabled}
                id={id || undefined}
            >
                {header}

                {collapsed ? closedIcon : openIcon}
            </button>
            <div className={styles.content}>{children}</div>
        </div>
    )
}

export default Collapsible
