import React, { useEffect, useState } from 'react'
import TextInput from 'components/textInput'
import Button from 'components/button'
import { sendEmailAnalytics } from 'features/analytics'
import { capitalise } from 'utils/string'
import { ALPHA_NUM_EXTENDED, EMAIL_REGEX, PHONE_NUMBER_REGEX } from '../../../config/constants'
import { useCheckoutContext } from '../../../contexts/checkout'
import { MarketingConsent } from './components/marketingConsent'
import { omniconvertContactDetailsGoal } from '../confirmation/useOmniconvertGoal'

interface ContactFormSectionProps {
    setContactSectionValid: React.Dispatch<React.SetStateAction<boolean>>
    contactSectionValid: boolean
    onSubmit: () => void
}

function ContactFormSection({
    setContactSectionValid,
    contactSectionValid,
    onSubmit,
}: ContactFormSectionProps): JSX.Element {
    const { state, dispatch } = useCheckoutContext()

    const [inputErrors, setInputErrors] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    })

    const inputRegex = {
        firstName: new RegExp(ALPHA_NUM_EXTENDED),
        lastName: new RegExp(ALPHA_NUM_EXTENDED),
        phone: new RegExp(PHONE_NUMBER_REGEX),
        email: new RegExp(EMAIL_REGEX),
    }

    const isInputValid = (field: keyof typeof inputRegex, value?: string) => {
        if (!value) {
            return false
        }

        if (!inputRegex[field].test(value)) {
            return false
        }

        return true
    }

    const onInputBlur = (field: keyof typeof inputRegex) => {
        const errorMessageToField = {
            firstName: 'This field must contain letters and digits only',
            lastName: 'This field must contain letters and digits only',
            phone: 'This field must contain 10 or 11 digits and no country code',
            email: 'This field must follow a valid email format',
        }

        if ((state[field] || '') === '') {
            setInputErrors((prevState) => ({ ...prevState, [field]: 'This field is required' }))
        }

        if (!isInputValid(field, state[field])) {
            setInputErrors((prevState) => ({ ...prevState, [field]: errorMessageToField[field] }))
        } else {
            setInputErrors((prevState) => ({ ...prevState, [field]: '' }))
        }
    }

    const onInputChange = (field: keyof typeof inputRegex, value: string) => {
        if (isInputValid(field, value)) {
            setInputErrors((prevState) => ({ ...prevState, [field]: '' }))
        }

        dispatch({ type: 'setCheckout', data: { [field]: value } })
    }

    useEffect(() => {
        setContactSectionValid(false)

        if (
            isInputValid('firstName', state.firstName) &&
            isInputValid('lastName', state.lastName) &&
            isInputValid('email', state.email) &&
            isInputValid('phone', state.phone)
        ) {
            setContactSectionValid(true)
        } else {
            setContactSectionValid(false)
        }
    }, [state.firstName, state.lastName, state.email, state.phone])

    const onNextClick = () => {
        if (contactSectionValid) {
            sendEmailAnalytics({
                email: state.email || 'unknown',
                selectedProduct: {
                    type: capitalise(state.packageType || ''),
                    name: state.packageName?.replace(/\s/, ''),
                },
            })
            omniconvertContactDetailsGoal()
            onSubmit()
        }
    }

    return (
        <div>
            <TextInput
                id="HomeCareCover_Checkout_Contact_ChangeFirstName"
                testId="firstName"
                placeholder="First Name"
                value={state.firstName || ''}
                onChange={(value) => onInputChange('firstName', value)}
                onBlur={() => onInputBlur('firstName')}
                error={inputErrors.firstName}
                className="maskpii sessioncamexclude sessioncamhidetext"
            />
            <TextInput
                id="HomeCareCover_Checkout_Contact_ChangeLastName"
                testId="lastName"
                placeholder="Last Name"
                value={state.lastName || ''}
                onChange={(value) => onInputChange('lastName', value)}
                onBlur={() => onInputBlur('lastName')}
                error={inputErrors.lastName}
                className="maskpii sessioncamexclude sessioncamhidetext"
            />
            <TextInput
                id="HomeCareCover_Checkout_Contact_ChangePhone"
                testId="phone"
                placeholder="Preferred Phone Number"
                value={state.phone || ''}
                onChange={(value) => onInputChange('phone', value)}
                onBlur={() => onInputBlur('phone')}
                error={inputErrors.phone}
                className="maskpii sessioncamexclude sessioncamhidetext"
            />
            <TextInput
                id="HomeCareCover_Checkout_Contact_ChangeEmail"
                testId="email"
                placeholder="Email"
                value={state.email || ''}
                onChange={(value) => onInputChange('email', value)}
                onBlur={() => onInputBlur('email')}
                error={inputErrors.email}
                className="maskpii sessioncamexclude sessioncamhidetext"
            />
            <MarketingConsent />
            <Button
                id="HomeCareCover_Checkout_Contact_SubmitSection"
                onClick={onNextClick}
                disabled={!contactSectionValid}
            >
                Next
            </Button>
        </div>
    )
}

export default ContactFormSection
