// eslint-disable-next-line import/prefer-default-export
export interface AnalyticsEventEcommerceObj {
    event: string
    ecommerce: {
        transaction_id: string | null
        value: string
        currency: 'GBP'
        items: {
            item_id: string
            item_name: string
            currency: 'GBP'
            item_category: string
            item_category2: string
            item_category3: string
            price: string
            quantity: number
        }[]
    }
}

export const newAnalyticsEvent = (eventPayload: AnalyticsEventEcommerceObj): void => {
    window.dataLayer?.push({ ...eventPayload })
}

export const oldAnalyticsEvent = (category: string, action: string, label: string): void => {
    window.dataLayer?.push({
        event: 'EventsJourney',
        category,
        action,
        label,
    })
}
