import styles from './index.module.scss'

interface TextInputProps {
    id?: string
    testId?: string
    header?: string
    placeholder: string
    value: string
    onChange: (value: string) => void
    onBlur?: (value: string) => void
    error?: string
    className?: string
}

function TextInput({
    id,
    testId,
    header,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    className,
}: TextInputProps): JSX.Element {
    return (
        <div className={`${styles.inputContainer} ${className || ''} ${!!error && styles.error}`}>
            {header && <label htmlFor={id ? `${id}` : 'unknown'}>{header}</label>}
            <input
                id={id}
                data-testid={testId || ''}
                type="text"
                placeholder={placeholder}
                value={value || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (typeof onBlur !== 'undefined') {
                        onBlur(e.target.value)
                    }
                }}
            />
            {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
    )
}

export default TextInput
