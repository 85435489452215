const PANDORA_API_BASE_URL = process.env.REACT_APP_PANDORA_API_URL

export const updateLeadUrl = async (leadToken: string) => {
    await fetch(`${PANDORA_API_BASE_URL}/leads-public/v1/lead-url/${leadToken}`, {
        method: 'PATCH',
        headers: {
            'Content-type': 'application/json',
        },
        mode: 'cors',
    })
}
