// eslint-disable-next-line import/prefer-default-export
export const getCookieDomain = () => {
    switch (window.location.hostname) {
        case 'ctm.hometree.local':
            return '.hometree.local'
        case 'www.checkout.hometree.co.uk':
        case 'checkout.hometree.co.uk':
        case 'checkout.stage.hometree.co.uk':
        case 'checkout.dev.hometree.co.uk':
            return '.hometree.co.uk'
        case 'hometree.comparethemarket.com':
            return '.comparethemarket.com'
        case 'localhost':
            return 'localhost'
        case '0.0.0.0':
            return '0.0.0.0'
        // cypress container hostname is app
        case 'app':
            return 'app'
        default:
            return '.hometree.co.uk'
    }
}
