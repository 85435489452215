import styles from './index.module.scss'

interface Props {
    id: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
    name?: string
    children: JSX.Element | string
}

function Checkbox({ id, onChange, checked, name, children }: Props) {
    return (
        <div className={styles.radioContainer}>
            <label>
                <div>
                    <input
                        type="radio"
                        name={name || ''}
                        onChange={onChange}
                        id={id}
                        checked={checked}
                    />
                    <span />
                </div>

                {children}
            </label>
        </div>
    )
}

export default Checkbox
