import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

const getEnvironment = () => {
    switch (window.location.hostname) {
        case 'www.checkout.hometree.co.uk':
        case 'checkout.hometree.co.uk':
            return 'production'
        case 'checkout.stage.hometree.co.uk':
            return 'staging'
        case 'checkout.dev.hometree.co.uk':
            return 'development'
        default:
            return 'local'
    }
}

const initializeSentry = (): void => {
    const env = getEnvironment()
    const envionmentsWithSentry = ['development', 'staging', 'production']
    if (!envionmentsWithSentry.includes(env)) {
        return
    }
    Sentry.init({
        dsn: 'https://449782e413e54057aff689f5cb62f533@o223561.ingest.sentry.io/5864166',
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        environment: env,
        tracesSampleRate: 1.0,
    })
}

export default initializeSentry
