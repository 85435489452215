import { INTEGRATION_ENDPOINT } from 'config/constants'
import { QueryFunction } from '@tanstack/react-query'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL

type VerifyReferralResponse = {
    data: {
        first_name?: string
        referralCode?: string
        accountId?: string
        message?: string
        errorCode?: string
        code?: string
    }
}

type VerifyReferralCodeQueryKey = [string, { referralCode: string; email?: string }]
export const verifyReferralcode: QueryFunction<
    VerifyReferralResponse['data'],
    VerifyReferralCodeQueryKey
> = async ({ queryKey }) => {
    const [, { referralCode, email }] = queryKey

    const url = `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/customer/verifyReferral/${referralCode.substring(
        3
    )}${email ? `?referrerAccountEmail=${encodeURIComponent(email)}` : ''}`
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    })
    // Fetch does not automatically throw errors
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    const json: VerifyReferralResponse = await response.json()
    return json.data
}
