import { PaymentMethod } from '@adyen/adyen-web/dist/types/types'
import { INTEGRATION_ENDPOINT } from 'config/constants'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL

export interface ResponsePayload {
    data: {
        paymentMethods: PaymentMethod[]
    }
}

export const adyenListPaymentMethods = async (packageId: number) => {
    try {
        const response = await fetch(
            `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/adyen/payment-methods?packageId=${packageId}`,
            {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                },
                mode: 'cors',
            }
        )
        const res = (await response.json()) as ResponsePayload

        if (!res.data.paymentMethods) {
            throw new Error("Couldn't fetch adyen payment methods")
        }

        return res.data
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error when listing adyen payment methods', error)
        throw error
    }
}
