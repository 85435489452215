import Button from 'components/button'
import { ReactComponent as BookEnjoyer } from '../../../../assets/ctm/marketing/book-enjoyer.svg'
import { ReactComponent as HeatPump } from '../../../../assets/ctm/marketing/heat-pump.svg'
import styles from './index.module.scss'

function Marketing() {
    return (
        <div className={`${styles.container}`}>
            <div className={styles.row}>
                <div className={styles.image}>
                    <BookEnjoyer />
                </div>
                <div className={styles.text}>
                    <h1>Why Hometree - maintain, fix, replace & finance</h1>
                    <p>
                        Experience comprehensive support with annual boiler maintenance and 24/7
                        claims support. In the event of an irreparable breakdown and your boiler is
                        more than 7 years old, we offer a 15% discount on a brand-new
                        energy-efficient system. Plus, with optional finance, you can pay us back on
                        a schedule that works for you.
                    </p>
                    <a href="https://www.hometree.co.uk/newboilers/">
                        <Button>Learn more</Button>
                    </a>
                </div>
            </div>
            <div className={`${styles.row} ${styles.secondRow}`}>
                <div className={styles.text}>
                    <h1>For gas boilers today and heat pumps tomorrow</h1>
                    <p>
                        A-grade boilers might be more efficient than older models, but it&apos;s
                        heat pumps that are the future of home energy. Heat pumps are great for
                        keeping your home energy efficient and they&apos;re kinder to the planet.
                        Not all UK homes are ready for heat pumps just yet, but we&apos;re here to
                        make it easier when you decide you want to learn more or make the
                        transition.
                    </p>
                    <a href="https://www.hometree.co.uk/">
                        <Button>Learn more</Button>
                    </a>
                </div>
                <div className={styles.image}>
                    <HeatPump />
                </div>
            </div>
        </div>
    )
}

export default Marketing
