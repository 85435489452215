import { CheckoutReducerAction } from 'contexts/checkout/type'
import { CookieDataType } from 'types/formData'

export const updateCookieData = (
    cookie: CookieDataType,
    action: CheckoutReducerAction
): CookieDataType => {
    const {
        firstName,
        lastName,
        email,
        phone,
        street,
        town,
        county,
        postcode,
        promoCode,
        contribution,
        packageId,
        packageType,
        date,
        billingType,
        monthlyDiscount,
        monthlyTotalPrice,
        packageName,
        nextServiceDate,
        referralLink,
        referralCode,
        marketingEmail,
        marketingPhone,
        marketingSms,
        referralProgramme,
        registerCustomerRequest,
        salesAgent,
        source,
    } = action.data

    const cookieData: CookieDataType = {
        ...cookie,
        ...(firstName && { forename: firstName }),
        ...(lastName && { surname: lastName }),
        ...(email && { email_address: email }),
        ...(phone && { contact_tel: phone }),
        ...(street && { street }),
        ...(town && { town }),
        ...(county && { county }),
        ...(postcode && { postcode }),
        ...((contribution || contribution === 0) && { contribution: `${contribution}` }),
        ...(packageType && { packageType: `${packageType}` }),
        ...(packageId && { packageId: `${packageId}` }),
        ...(date && { date: `${date}` }),
        ...(billingType && { billing: billingType }),
        ...(typeof monthlyDiscount !== 'undefined' &&
            !Number.isNaN(monthlyDiscount) && { monthlyDiscount }),
        ...(monthlyTotalPrice && { monthlyTotalPrice }),
        ...(packageName && { productName: packageName }),
        ...(nextServiceDate && { nextServiceDate }),
        ...(referralLink && { referralLink }),
        ...(typeof promoCode !== 'undefined' && { promoCode }),
        ...(typeof referralCode === 'string' && { referralCode }),
        ...(typeof marketingEmail !== 'undefined' && { marketingEmail }),
        ...(typeof marketingPhone !== 'undefined' && { marketingPhone }),
        ...(typeof marketingSms !== 'undefined' && { marketingSms }),
        ...(typeof referralProgramme !== 'undefined' && { referralProgramme }),
        ...(registerCustomerRequest && { registerCustomerRequest }),
        ...(typeof salesAgent !== 'undefined' && { salesAgent }),
        ...(typeof source !== 'undefined' && { source }),
    }

    return cookieData
}
