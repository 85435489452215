import styles from './index.module.scss'

type BubbleHeadingProps = {
    children: React.ReactNode
    className?: string
}

function BubbleHeading({ children, className }: BubbleHeadingProps): JSX.Element {
    return (
        <div className={`${styles.heading} ${className}`}>
            <h1>{children}</h1>
        </div>
    )
}

export default BubbleHeading
