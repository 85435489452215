import Button from 'components/button'
import { Fragment, useContext } from 'react'
import { ReactComponent as Dash } from 'assets/dash.svg'
import { ReactComponent as Check } from 'assets/tick.svg'
import { ReactComponent as Cross } from 'assets/x-red.svg'
import { ReactComponent as Strike } from 'assets/strike.svg'
import Collapsible from 'components/collapsible'
import { PromoContext } from 'features/promoMode'
import styles from './planCard.module.scss'

type PlanCardProps = {
    title: string
    subtitle: string
    subheading?: string
    price: string | null
    originalPrice?: string
    billing: 'annually' | 'monthly'
    onPlanClick: () => void
    items: {
        label: string
        popover: string
        value: React.ReactNode | string
    }[]
    inclusions?: {
        name: string
        included: string[]
        excluded: string[]
        state: {
            included: {
                value: boolean
                handler: React.Dispatch<React.SetStateAction<boolean>>
            }
            excluded: {
                value: boolean
                handler: React.Dispatch<React.SetStateAction<boolean>>
            }
        }
    }
    disabled?: boolean
    essentials?: boolean
}

function PlanCard({
    items,
    title,
    subtitle,
    subheading,
    price,
    originalPrice,
    billing,
    onPlanClick,
    disabled,
    inclusions,
    essentials = false,
}: PlanCardProps) {
    const { phase: promoPhase } = useContext(PromoContext)
    const promoPeriodActive = !!promoPhase

    return (
        <div className={styles.card}>
            <div className={`${styles.cardHeader} ${disabled && styles.disabled} plan-card-header`}>
                <h3>{title}</h3>
                <span>{subtitle}</span>

                <span
                    className={`${styles.price} ${title === 'Your Home' && styles.priceYourHome}`}
                >
                    {promoPeriodActive &&
                    promoPhase?.ctm?.plans.strikethrough &&
                    !essentials &&
                    originalPrice &&
                    originalPrice !== price ? (
                        <h4>
                            <Strike />
                            {originalPrice}
                        </h4>
                    ) : (
                        <></>
                    )}
                    <h2>{price || <Dash />}</h2>
                </span>
                <span>Billed {billing}</span>

                {subheading && (
                    <div className={styles.subheading}>
                        <h4>{subheading}</h4>
                    </div>
                )}

                {title === 'Your Boiler' &&
                    promoPeriodActive &&
                    promoPhase?.ctm?.stickers.enabled &&
                    !!promoPhase?.ctm?.stickers.yourBoiler && (
                        <div className={styles.discount}>{promoPhase.ctm.stickers.yourBoiler}</div>
                    )}
                {title === 'Your Heating' &&
                    promoPeriodActive &&
                    promoPhase?.ctm?.stickers.enabled &&
                    !!promoPhase?.ctm?.stickers.yourHeating && (
                        <div className={styles.discount}>{promoPhase.ctm.stickers.yourHeating}</div>
                    )}
                {title === 'Your Home' &&
                    promoPeriodActive &&
                    promoPhase?.ctm?.stickers.enabled &&
                    !!promoPhase?.ctm?.stickers.yourHome && (
                        <div className={styles.discount}>{promoPhase.ctm.stickers.yourHome}</div>
                    )}
            </div>
            <div className={styles.cardCta}>
                <Button
                    id={`HomeCareCover_CoverType_${title.replace(' ', '')}_SeePrices`}
                    onClick={onPlanClick}
                    disabled={!!disabled}
                >
                    Select Plan
                </Button>
            </div>
            <div className={styles.cardItems}>
                {items.map((item) => {
                    return (
                        <Fragment key={`${title} - ${item.label} `}>
                            <div className={`${styles.popover} ${styles.cardLabel}`}>
                                <div className={styles.popoverTitle}>{item.label}</div>
                                <div className={styles.popoverContent}>{item.popover}</div>
                            </div>
                            <div className={`${styles.cardValue} ${disabled && styles.disabled}`}>
                                {item.value}
                            </div>
                        </Fragment>
                    )
                })}
            </div>
            {inclusions && (
                <div className={styles.inclusions}>
                    <hr />
                    <Collapsible
                        id={`HomeCareCover_CoverType_${title.replace(' ', '')}_SeeInclusions`}
                        header={<h4>What&apos;s included</h4>}
                        collapsed={inclusions.state.included.value}
                        onHeaderClick={() =>
                            inclusions.state.included.handler(!inclusions.state.included.value)
                        }
                        plusVariant="thick"
                        variant="inclusions"
                    >
                        <span className={styles.bullets}>
                            {inclusions.included.map((bullet) => (
                                <span
                                    className={styles.inclusionsPoint}
                                    key={bullet.substring(0, 5)}
                                >
                                    <Check />
                                    <p>{bullet}</p>
                                </span>
                            ))}
                        </span>
                    </Collapsible>{' '}
                    <hr />
                    <Collapsible
                        id={`HomeCareCover_CoverType_${title.replace(' ', '')}_SeeExclusions`}
                        header={<h4>What&apos;s not included</h4>}
                        collapsed={inclusions.state.excluded.value}
                        onHeaderClick={() =>
                            inclusions.state.excluded.handler(!inclusions.state.excluded.value)
                        }
                        plusVariant="thick"
                        variant="inclusions"
                    >
                        <span className={styles.bullets}>
                            {inclusions.excluded.map((bullet) => (
                                <span
                                    className={styles.inclusionsPoint}
                                    key={bullet.substring(0, 5)}
                                >
                                    <Cross />
                                    <p>{bullet}</p>
                                </span>
                            ))}
                        </span>
                    </Collapsible>{' '}
                    <hr />
                </div>
            )}
        </div>
    )
}

export default PlanCard
