import { Product } from 'types/catalogue'

const formatProductNames = (products: Product[]): Product[] => {
    const productsArray = [...products]

    const windowsLocksDoorsProducts = productsArray.filter((product: Product) => {
        return ['Windows', 'Locks', 'Doors'].includes(product.name)
    })

    if (windowsLocksDoorsProducts.length === 3) {
        productsArray.unshift({
            name: 'Home Security',
            featured: 1,
            included: 1,
            product_id: (windowsLocksDoorsProducts[0].product_id as number) || 0,
            description: 'Home Security',
            landlord_only: 0,
            limit_per_contract: 0,
            position: (windowsLocksDoorsProducts[0].position as number) || 0,
        })
    }

    return productsArray
        .filter((product: Product) => {
            return !['Windows', 'Locks', 'Doors'].includes(product.name)
        })
        .map((product) => ({
            ...product,
            name: product.name === 'Pests' ? 'Pest Control' : product.name,
        }))
}

export default formatProductNames
