import { useGoCardlessDropin, GoCardlessDropinOptions } from '@gocardless/react-dropin'
import { sendPageLandAnalytics } from 'features/analytics'
import { forwardRef, useEffect, useImperativeHandle } from 'react'

interface GCDropInProps {
    gcDropInOptions: GoCardlessDropinOptions
}

export interface GCDropInRef {
    handleGCDropInOpen: () => void
}

const GCDropIn = forwardRef<GCDropInRef, GCDropInProps>(({ gcDropInOptions }, ref) => {
    const { open } = useGoCardlessDropin({ ...gcDropInOptions })

    useEffect(() => {
        sendPageLandAnalytics('DirectDebitModal', 'PaymentPage')
    }, [])

    useImperativeHandle(ref, () => ({
        handleGCDropInOpen: open,
    }))

    return <></>
})

export default GCDropIn
