import { useState } from 'react'
import { isAlphaNumeric, isPhoneNumber, isPostcode } from 'utils/validation'
import TextInput from 'components/textInput'
import Dropdown from 'components/dropdown'
import { useCheckoutContext } from 'contexts/checkout'
import Checkbox from 'components/checkbox'
import styles from './billingAddressForm.module.scss'

export interface BillingAddressState {
    country: string
    firstName: string
    lastName: string
    phoneNumber: string
    line1: string
    line2: string
    town: string
    county: string
    postcode: string
}

interface BillingAddressFormProps {
    onChange: (key: string, value: string) => void
    setIsBillingAddressValid: (valid: boolean) => void
    setBillingAddressState: (state: BillingAddressState) => void
    state: BillingAddressState
}

function BillingAddressForm({
    onChange,
    state,
    setBillingAddressState,
    setIsBillingAddressValid,
}: BillingAddressFormProps): JSX.Element {
    const { state: checkoutState } = useCheckoutContext()
    const [propertySameAsBillingAddress, setPropertySameAsBillingAddress] = useState(true)

    const [addressErrors, setAddressErrors] = useState({
        country: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        line1: '',
        line2: '',
        town: '',
        county: '',
        postcode: '',
    })

    const handleOnBlur = (key: keyof BillingAddressState, value: string) => {
        let isValid = true
        const latestState = { ...state, [key]: value }

        const errors = {
            ...addressErrors,
        }

        // First name
        if (!isAlphaNumeric(latestState.firstName)) {
            if (key === 'firstName') {
                errors.firstName = 'Please enter a valid first name'
            }
            isValid = false
        } else {
            errors.firstName = ''
        }

        // Last name
        if (!isAlphaNumeric(latestState.lastName)) {
            if (key === 'lastName') {
                errors.lastName = 'Please enter a valid last name'
            }
            isValid = false
        } else {
            errors.lastName = ''
        }

        // Phone number
        if (!isPhoneNumber(latestState.phoneNumber)) {
            if (key === 'phoneNumber') {
                errors.phoneNumber = 'Please enter a valid phone number'
            }
            isValid = false
        } else {
            errors.phoneNumber = ''
        }

        // Line 1
        if (!isAlphaNumeric(latestState.line1)) {
            if (key === 'line1') {
                errors.line1 = 'Please enter a valid line 1'
            }
            isValid = false
        } else {
            errors.line1 = ''
        }

        // Town
        if (!isAlphaNumeric(latestState.town)) {
            if (key === 'town') {
                errors.town = 'Please enter a valid town'
            }
            isValid = false
        } else {
            errors.town = ''
        }

        // Postcode
        if (!isPostcode(latestState.postcode)) {
            if (key === 'postcode') {
                errors.postcode = 'Please enter a valid postcode'
            }
            isValid = false
        } else {
            errors.postcode = ''
        }

        setIsBillingAddressValid(isValid)
        setAddressErrors(errors)
    }

    const handleOnUsePropertyAsBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPropertySameAsBillingAddress(e.target.checked)
        setBillingAddressState({
            country: 'United Kingdom',
            firstName: checkoutState.firstName || '',
            lastName: checkoutState.lastName || '',
            phoneNumber: checkoutState.phone || '',
            line1: checkoutState.street || '',
            line2: '',
            town: checkoutState.town || '',
            county: checkoutState.county || '',
            postcode: checkoutState.postcode || '',
        })

        if (e.target.checked) {
            setIsBillingAddressValid(true)
        }
    }

    return (
        <div className={styles.billingAddressForm}>
            <div className={styles.usePropertyAsBillingCheckbox}>
                <Checkbox
                    id="usePropertyAsBilling"
                    onChange={handleOnUsePropertyAsBillingChange}
                    checked={propertySameAsBillingAddress}
                >
                    <strong>Use my property details as billing address</strong>
                </Checkbox>
                {propertySameAsBillingAddress && (
                    <p>
                        {state.line1}
                        <br />
                        {state.line2 && (
                            <>
                                {state.line2}
                                <br />
                            </>
                        )}
                        {state.town} {state.county && `${state.county},`} {state.postcode}, GB
                    </p>
                )}
            </div>

            {!propertySameAsBillingAddress && (
                <div>
                    <h3>Your personal details </h3>
                    <Dropdown
                        header="Country of residence"
                        options={[
                            {
                                value: 'United Kingdom',
                                displayValue: 'United Kingdom',
                            },
                        ]}
                        onChange={(value) => {
                            onChange('country', value)
                        }}
                        className={styles.inputContainer}
                    />

                    <div className={styles.rowInputs}>
                        <TextInput
                            id="HomeCareCover_Checkout_Chargebee_ChangeFirstName"
                            testId="firstName"
                            placeholder=""
                            header="First name"
                            value={state.firstName || ''}
                            onChange={(value) => {
                                onChange('firstName', value)
                            }}
                            onBlur={(value) => {
                                handleOnBlur('firstName', value)
                            }}
                            error={addressErrors.firstName}
                            className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                        />
                        <TextInput
                            id="HomeCareCover_Checkout_Chargebee_ChangeLastName"
                            testId="lastName"
                            placeholder=""
                            header="Last name"
                            value={state.lastName || ''}
                            onChange={(value) => {
                                onChange('lastName', value)
                            }}
                            onBlur={(value) => {
                                handleOnBlur('lastName', value)
                            }}
                            error={addressErrors.lastName}
                            className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                        />
                    </div>
                    <TextInput
                        id="HomeCareCover_Checkout_Chargebee_ChangePhone"
                        testId="phoneNumber"
                        placeholder=""
                        header="Phone number"
                        value={state.phoneNumber || ''}
                        onChange={(value) => {
                            onChange('phoneNumber', value)
                        }}
                        onBlur={(value) => {
                            handleOnBlur('phoneNumber', value)
                        }}
                        error={addressErrors.phoneNumber}
                        className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                    />
                    <TextInput
                        id="HomeCareCover_Checkout_Chargebee_ChangeAddress1"
                        testId="line1"
                        placeholder=""
                        header="Billing address line 1"
                        value={state.line1 || ''}
                        onChange={(value) => {
                            onChange('line1', value)
                        }}
                        onBlur={(value) => {
                            handleOnBlur('line1', value)
                        }}
                        error={addressErrors.line1}
                        className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                    />
                    <TextInput
                        id="HomeCareCover_Checkout_Chargebee_ChangeAddress2"
                        testId="line2"
                        placeholder=""
                        header="Billing address line 2 (optional)"
                        value={state.line2 || ''}
                        onChange={(value) => {
                            onChange('line2', value)
                        }}
                        onBlur={(value) => {
                            handleOnBlur('line2', value)
                        }}
                        error={addressErrors.line2}
                        className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                    />

                    <div className={styles.rowInputs}>
                        <TextInput
                            id="HomeCareCover_Checkout_Chargebee_ChangeTown"
                            testId="town"
                            placeholder=""
                            header="Town or City"
                            value={state.town || ''}
                            onChange={(value) => {
                                onChange('town', value)
                            }}
                            onBlur={(value) => {
                                handleOnBlur('town', value)
                            }}
                            error={addressErrors.town}
                            className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                        />
                        <TextInput
                            id="HomeCareCover_Checkout_Chargebee_ChangePostcode"
                            testId="postcode"
                            placeholder=""
                            header="Postcode"
                            value={state.postcode || ''}
                            onChange={(value) => {
                                onChange('postcode', value)
                            }}
                            onBlur={(value) => {
                                handleOnBlur('postcode', value)
                            }}
                            error={addressErrors.postcode}
                            className={`${styles.inputContainer} maskpii sessioncamexclude sessioncamhidetext`}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default BillingAddressForm
