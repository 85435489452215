import Button from 'components/button'
import styles from './index.module.scss'

interface InputButtonGroupProps {
    id: string
    dataTestId?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClick: () => void
    buttonText: string
    value: string
    error?: string
    disabled?: boolean
    label?: string
    placeholder?: string
    inputClassName?: string
}

function InputButtonGroup({
    id,
    dataTestId,
    onChange,
    onClick,
    buttonText,
    value,
    error,
    disabled,
    label,
    placeholder,
    inputClassName,
}: InputButtonGroupProps): JSX.Element {
    const onEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClick()
        }
    }

    return (
        <div
            className={`${styles.inputButtonGroupContainer} ${!!error && styles.error}`}
            data-testid={dataTestId ? `inputButtonGroup-${dataTestId}` : undefined}
        >
            <label htmlFor={id}>
                {label && <span>{label}</span>}

                <fieldset>
                    <input
                        type="text"
                        data-testid={dataTestId}
                        value={value}
                        onChange={onChange}
                        onKeyPress={onEnterKeyPress}
                        placeholder={placeholder || ''}
                        className={inputClassName}
                    />
                    <Button
                        id={id}
                        dataTestId={dataTestId ? `${dataTestId}-button` : undefined}
                        type="button"
                        disabled={!value || disabled}
                        className="btn-warning"
                        onClick={onClick}
                    >
                        {buttonText}
                    </Button>
                </fieldset>
            </label>
            {!!error && <div className={styles.errorMessage}>{error}</div>}
        </div>
    )
}

export default InputButtonGroup
