import { useEffect } from 'react'
import { ENVIRONMENT, getMandatoryEnvVar } from 'utils/env'

const ENV = getMandatoryEnvVar('REACT_APP_NODE_ENV')

export const useOmniconvertGoal = (
    contractId: number | undefined,
    monthlyTotalPrice: number | undefined
) => {
    useEffect(() => {
        if (contractId && typeof monthlyTotalPrice !== 'undefined') {
            if (ENV === ENVIRONMENT.PROD || ENV === ENVIRONMENT.STAGE) {
                const script = document.createElement('script')

                // this script is pushing a custom goal called 'sale' with the monthly total price as a value and the contractId as the transaction id
                script.innerHTML = `_mktz.push(['_Goal','sale','${
                    monthlyTotalPrice / 100
                }',{transaction:'${contractId}'}]);`
                script.async = true

                document.body.appendChild(script)
            }
        } else {
            // eslint-disable-next-line no-console
            console.error('Contract id or monthly price undefined in state at end of confirmation')
        }
    }, [])
}

export const omniconvertContactDetailsGoal = () => {
    if (ENV === ENVIRONMENT.PROD || ENV === ENVIRONMENT.STAGE) {
        const script = document.createElement('script')

        // this script is pushing a custom goal called 'sale' with the monthly total price as a value and the contractId as the transaction id
        script.innerHTML = `_mktz.push(['_Goal','checkout-contactinfo-next','1']);`
        script.async = true

        document.body.appendChild(script)
    } else {
        // eslint-disable-next-line no-console
        console.error('Contract id or monthly price undefined in state at end of confirmation')
    }
}
