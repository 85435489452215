import { ButtonHTMLAttributes } from 'react'
import styles from './index.module.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'dark' | 'transparent' | 'purple' | 'default'
    disabled?: boolean
    fullWidth?: boolean
    className?: string
    onClick?: (e: any) => void
    loading?: boolean
    dataTestId?: string
}

function Button({
    children,
    variant = 'primary',
    className = '',
    disabled,
    fullWidth = false,
    onClick,
    loading = false,
    dataTestId,
}: ButtonProps) {
    return (
        <button
            type="button"
            className={`${styles.button} ${className} ${styles[variant]} ${
                fullWidth && styles.fullWidth
            }`}
            disabled={disabled}
            onClick={onClick}
            data-testid={dataTestId}
        >
            {loading && <div className={styles.loading} />}
            {children}
        </button>
    )
}

export default Button
