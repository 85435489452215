import { ACCOUNTS_ENDPOINT } from 'config/constants'
import { CheckoutState } from 'contexts/checkout/type'
import { getMandatoryEnvVar } from 'utils/env'

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_PORTAL_API_BASE_URL')

export interface RequestPayload {
    marketing_email?: boolean
    marketing_sms?: boolean
    marketing_phone?: boolean
    customer_prefs_hash?: string
    account_id?: number
    contract_id?: number
    referral_programme: boolean
}

export const updateMarketingPreferences = async (state: CheckoutState, latestState: any) => {
    try {
        const input: RequestPayload = {
            marketing_email: state.marketingEmail,
            marketing_phone: state.marketingPhone,
            marketing_sms: state.marketingSms,
            customer_prefs_hash: latestState.customerPrefsHash,
            account_id: state.accountId,
            contract_id: state.contractId,
            referral_programme: state.referralProgramme,
        }

        const response = await fetch(`${API_BASE_URL}/${ACCOUNTS_ENDPOINT}/marketing/prefs`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(input),
        })

        return await response.json()
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error when updating marketing preferences', error)
        throw error
    }
}
