export const getLocalStorage = (...args: string[]): { [key: string]: string } => {
    const result: { [key: string]: string } = {}

    args.forEach((key: string) => {
        if (key) result[key] = localStorage.getItem(key) || ''
    })

    return result
}

export const removeLocalStorage = (...args: string[]): void => {
    args.forEach((key: string) => {
        localStorage.removeItem(key)
    })
}

export const setLocalStorage = (data: { [key: string]: string }): void => {
    Object.entries(data).forEach(([key, value]) => {
        localStorage.setItem(key, value)
    })
}
