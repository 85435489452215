import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { POSTCODES_ENDPOINT } from 'config/constants'
import Dropdown from 'components/dropdown'
import InputButtonGroup from 'components/inputButtonGroup'
import { Address } from '../../../types/address'
import { createAddressObject } from '../../../utils/createAddressObject'
import { formatAddress } from '../../../utils/formatAddress'
import { isPostcode } from '../../../utils/validation'
import styles from './index.module.scss'

const API_BASE_URL = process.env.REACT_APP_CHECKOUT_API_BASE_URL

interface PostcodeCheckResponse {
    covered: boolean
    postcode: string
    addresses: string[]
    latitude: number
    longitude: number
}
interface PostcodeFinderProps {
    // eslint-disable-next-line no-unused-vars
    onSelect: (address: Address) => void
    onSearch: () => void
    postcode: string
    setPostcode: (value: string) => void
}

function PostcodeFinder({
    onSelect,
    onSearch,
    postcode,
    setPostcode,
}: PostcodeFinderProps): JSX.Element {
    const [addresses, setAddresses] = useState<string[]>([])
    const [error, setError] = useState('')

    const onClick = async () => {
        setError('')
        setAddresses([])

        if (!isPostcode(postcode)) {
            setError('Please enter a valid postcode')
            return
        }

        try {
            const result = await fetch(`${API_BASE_URL}/${POSTCODES_ENDPOINT}/pub/v1/pc`, {
                method: 'POST',
                headers: {
                    // Currently the postcode endpoint does not support cors properly
                    'Content-type': 'text/plain',
                },
                body: JSON.stringify({
                    action: 'processPostcode',
                    postcode,
                    productType: 'Homecover',
                }),
            })

            // TODO review what error to throw here. Something more useful
            if (!result.ok) throw new Error('Error getting addresses')

            const data = (await result.json()).data as PostcodeCheckResponse

            if (data.addresses.length > 0) {
                setAddresses(data.addresses.map((address) => `${address}, ${data.postcode}`))
                onSearch()
            } else {
                setError('No addresses found')
            }
        } catch (err) {
            Sentry.captureException(err)
            setError('Error finding addresses')
        }
    }

    const onSelectAddress = (address: string) => {
        const addressObject = createAddressObject(address)

        // Check if of type Address
        if (addressObject.line1) {
            onSelect(addressObject as Address)
        } else {
            Sentry.captureMessage('Error creating address object. Address not in correct format')
        }
    }

    let addressOptions = addresses.map((address) => {
        return {
            value: address,
            displayValue: formatAddress(address),
            disabled: false,
        }
    })
    addressOptions = [
        { value: 'placeholder', displayValue: 'Select full address', disabled: true },
    ].concat(addressOptions)
    return (
        <div className={styles.postcodeFinder} data-testid="postcodeFinder">
            <InputButtonGroup
                id="HomeCareCover_Checkout_Address_ChangeFindPostcode"
                dataTestId="postcode"
                onChange={(e) => setPostcode(e.target.value.toUpperCase())}
                onClick={onClick}
                buttonText="Find"
                value={postcode}
                error={error}
                placeholder="Postcode"
                inputClassName="maskpii sessioncamexclude sessioncamhidetext"
            />

            {addresses.length > 0 && (
                <Dropdown
                    testId="addresses"
                    defaultValue="placeholder"
                    onChange={onSelectAddress}
                    options={addressOptions}
                />
            )}
        </div>
    )
}

export default PostcodeFinder
