import { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { PromoCode } from 'types/promocode'
import formatName from 'utils/formatName'
import formatProductNames from 'utils/formatProductNames'
import { penceToPounds } from 'utils/penceToPounds'
import { ReactComponent as TickLogo } from 'assets/tick.svg'
import { useCheckoutContext } from 'contexts/checkout'
import {
    PricebookEntry,
    GetCatalogueResponse,
    CatalogueEntry,
    Product,
    getCatalogueResponseSchema,
} from 'types/catalogue'
import {
    calculateMonthlyPackagePrice,
    calculateMonthlyTotalPrice,
    toBillingPrice,
} from 'utils/checkoutPrice'
import { BillingType } from 'types/formData'
import { ReactComponent as Strike } from 'assets/strike.svg'
import { PromoContext } from 'features/promoMode'
import Ajv from 'ajv'
import { sendPageLandAnalytics } from 'features/analytics'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import styles from './index.module.scss'

export const formPricebookEntry = (entries: PricebookEntry[]): PricebookEntry => {
    const formedPricebookEntry: PricebookEntry = {
        entry_id: entries[0].entry_id,
        package: entries[0].package,
        price_nocontribution: 0,
        price_60contribution: 0,
        price_95contribution: 0,
    }

    for (let index = 0; index < entries.length; index++) {
        const entry = entries[index]
        if (!entry.price) {
            // eslint-disable-next-line no-continue
            continue
        }

        switch (entry.contribution) {
            case 0:
                formedPricebookEntry.price_nocontribution = entry.price
                break
            case 60:
                formedPricebookEntry.price_60contribution = entry.price
                break
            case 95:
                formedPricebookEntry.price_95contribution = entry.price
                break
            default:
                break
        }
    }

    return formedPricebookEntry
}

function OrderForm(): JSX.Element {
    const {
        state: {
            contribution,
            billingType: billing,
            packageId,
            pricebookId,
            monthlyTotalPrice,
            monthlyDiscount,
            promoCode,
            referralCode,
        },
        dispatch,
    } = useCheckoutContext()

    // All prices should be in pence
    const [pricebook, setPricebook] = useState<CatalogueEntry | null>(null)
    const [selectedPackage, setSelectedPackage] = useState<PricebookEntry | null>(null)
    const [, setPackagePrice] = useState(0)
    const [, setAddOnPrice] = useState(0)
    const [strikethroughPrice, setStrikethroughPrice] = useState<number | undefined>(undefined)

    const { phase: promoPhase } = useContext(PromoContext)
    const promoPeriodActive = !!promoPhase

    const isInsurancePackage = selectedPackage?.package.insurance === 1
    const isCorePackage = selectedPackage?.package.products?.some(
        (product: Product) => product.product_id === 1
    )

    const mapPackageType = {
        Homeowner: 'homeowner',
        Landlord: 'landlord',
    }

    const renderInsuranceTaxText = () =>
        isCorePackage
            ? `This price includes Insurance Premium Tax and one annual service visit at £72 incl. VAT`
            : `This price includes Insurance Premium Tax`

    useEffect(() => {
        let mounted = true

        const getCatalogue = async () => {
            try {
                const getCatalogueResponse = await fetch(
                    `${process.env.REACT_APP_PANDORA_API_URL}/pricebooks/model/pricebooks/catalogue/${pricebookId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-type': 'application/json',
                        },
                        mode: 'cors',
                    }
                )

                const response: GetCatalogueResponse = await getCatalogueResponse.json()

                const foundPricebook = response.data.find(
                    (entry: CatalogueEntry) => entry.pricebook_id === pricebookId
                )

                if (!foundPricebook) {
                    return
                }

                const foundEntries = foundPricebook.pricebook_entries.filter(
                    (entry: PricebookEntry) => entry.package.package_id === packageId
                )

                if (!foundEntries || !mounted) {
                    return
                }

                const formedPricebookEntry = formPricebookEntry(foundEntries)

                const calculatedAddOnPrice = 0 // currently no addons are available

                const newPackagePrice = calculateMonthlyPackagePrice(
                    contribution,
                    formedPricebookEntry
                )
                const newTotalPrice = calculateMonthlyTotalPrice(
                    formedPricebookEntry,
                    contribution,
                    calculatedAddOnPrice
                )

                const packageName = formatName(formedPricebookEntry?.package.name || 'Not found')

                setPricebook(foundPricebook)
                setSelectedPackage(formedPricebookEntry || null)
                setPackagePrice(newPackagePrice)
                setAddOnPrice(calculatedAddOnPrice)
                dispatch({
                    type: 'setCheckout',
                    data: {
                        packageType:
                            mapPackageType[formedPricebookEntry.package.type] || 'homeowner',
                        monthlyTotalPrice: newTotalPrice || 0,
                        packageName,
                        packageDetails: formedPricebookEntry?.package,
                    },
                })

                sendPageLandAnalytics('Checkout', 'PaymentPage', packageName.replace(/\s/g, ''))

                // TODO not very DRY
                if (promoPeriodActive && promoPhase?.pricebooks.fullPriceBook) {
                    const getFullPriceCatalogue = await fetch(
                        `${process.env.REACT_APP_PANDORA_API_URL}/pricebooks/model/pricebooks/catalogue/${promoPhase?.pricebooks.fullPriceBook}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            mode: 'cors',
                        }
                    )

                    const res = await getFullPriceCatalogue.json()

                    const ajv = new Ajv({ coerceTypes: true, allErrors: true })
                    const validator = ajv.compile(getCatalogueResponseSchema)
                    const valid = validator(res)

                    if (!valid) {
                        throw new Error('AJV error')
                    }

                    const fullPricebook = res.data.find(
                        (entry: CatalogueEntry) =>
                            entry.pricebook_id === promoPhase?.pricebooks.fullPriceBook
                    )

                    if (!fullPricebook) {
                        return
                    }

                    const foundEntriesFull = fullPricebook.pricebook_entries.filter(
                        (entry: PricebookEntry) => entry.package.package_id === packageId
                    )

                    if (!foundEntriesFull || !mounted) {
                        return
                    }

                    const formedPricebookEntryFull = formPricebookEntry(foundEntriesFull)

                    const fullPrice = calculateMonthlyTotalPrice(
                        formedPricebookEntryFull,
                        contribution,
                        0
                    )

                    setStrikethroughPrice(fullPrice)
                }
            } catch (err) {
                Sentry.captureException(err)
            }
        }

        if (packageId && pricebookId && !pricebook) {
            getCatalogue()
        }

        return () => {
            mounted = false
        }
    }, [packageId, pricebookId])

    useEffect(() => {
        if (!promoCode?.type || !promoCode?.value) {
            dispatch({
                type: 'setCheckout',
                data: {
                    monthlyDiscount: 0,
                },
            })
            return
        }

        let discount = 0
        // Discount.value is an integer
        if (promoCode.type === PromoCode.PERCENTAGE) {
            discount = Math.ceil(monthlyTotalPrice * (promoCode.value / 100))
        } else if (promoCode.type === PromoCode.VALUE) {
            // Value discounts don't mention how much it takes off, but may mislead
            // monthly payment users
            discount = (promoCode.value * 100) / 12
        }

        if (discount >= monthlyTotalPrice) {
            discount = monthlyTotalPrice
        }

        dispatch({
            type: 'setCheckout',
            data: {
                monthlyDiscount: discount,
            },
        })
    }, [monthlyTotalPrice, promoCode?.value, promoCode?.type, referralCode])

    const packageName = formatName(selectedPackage?.package.name || '')

    const productItems = formatProductNames(selectedPackage?.package.products || [])
        .filter(({ featured, included }) => featured && included)
        .map(({ product_id, name }: Product) => (
            <li key={product_id}>
                <TickLogo />
                <h3>{name}</h3>
            </li>
        ))

    const getProductListClass = () => {
        switch (productItems.length) {
            case 2:
            case 3:
            case 4:
            case 5:
                return styles.productListCol
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            default:
                return styles.productList2Cols
        }
    }

    return (
        <form className={styles.orderForm}>
            <div>
                <h1>Your Order</h1>
                <p>
                    This plan meets the needs of a customer who wants to cover the items ticked
                    below.
                </p>

                <hr />

                <h2>{packageName}</h2>
                <div className={styles.productList}>
                    <ul className={getProductListClass()}>{productItems}</ul>
                </div>

                <hr />
                <div className={styles.orderAdjustments}>
                    <div className={styles.contribution}>
                        <span>
                            <span className={styles.callout}>
                                <h4>Call-out fee </h4>
                                <span className={styles.popover}>
                                    <span>
                                        <InfoIcon />{' '}
                                    </span>
                                    <div className={styles.popoverContent}>
                                        <p>
                                            This is the excess which you pay under the insurance
                                            policy. You pay this once per fault. The higher the
                                            call-out fee you select, the lower the monthly or annual
                                            price you pay. We do not charge call-out fees for your
                                            service visit. You can change your call-out fee by
                                            returning to the previous page.
                                        </p>
                                    </div>
                                </span>
                            </span>
                            <h4>(only charged at call-out)</h4>
                        </span>
                        <h3>&pound;{contribution?.toFixed(2)}</h3>
                    </div>
                </div>

                <div className={styles.totalPrice}>
                    <div>
                        <h3>
                            Total price
                            <br />
                            {`(paid ${
                                billing === BillingType.ANNUAL ? 'annually' : BillingType.MONTHLY
                            })`}
                        </h3>
                        <span className={styles.priceValues}>
                            {promoPeriodActive &&
                            promoPhase?.checkout.strikethrough &&
                            !packageName?.includes('Essentials') &&
                            strikethroughPrice !== monthlyTotalPrice ? (
                                <span className={styles.ogPriceWrapper}>
                                    <Strike />
                                    <p className={styles.originalPrice}>
                                        &pound;
                                        {penceToPounds(
                                            Math.round(
                                                toBillingPrice(
                                                    (strikethroughPrice || 0) - monthlyDiscount ||
                                                        0,
                                                    billing || ''
                                                )
                                            )
                                        )}
                                    </p>
                                </span>
                            ) : (
                                <span className={styles.spacer} />
                            )}

                            <h2>
                                &pound;
                                {penceToPounds(
                                    Math.round(
                                        toBillingPrice(
                                            monthlyTotalPrice - monthlyDiscount || 0,
                                            billing || ''
                                        )
                                    )
                                )}
                            </h2>
                        </span>
                    </div>
                    {isInsurancePackage && <p>{renderInsuranceTaxText()}</p>}
                </div>
            </div>
        </form>
    )
}

export default OrderForm
