import Button from 'components/button'
import { ReactComponent as XIcon } from 'assets/x-dark-green.svg'
import { useEffect } from 'react'
import { ReactComponent as Loader } from 'assets/loader.svg'
import styles from './index.module.scss'

interface ModalProps {
    isOpen: boolean
    showLoading?: boolean
    showClose?: boolean
    onClose: () => void
    buttonText?: string
    className?: string
    children: React.ReactNode
    testId?: string
}

function Modal({
    isOpen,
    showLoading,
    showClose = true,
    onClose,
    buttonText,
    className,
    children,
    testId = '',
}: ModalProps) {
    // disable body scrolling when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [isOpen])

    return (
        <>
            {isOpen && (
                <div className={`${styles.overlay} ${className}`} data-testid={testId}>
                    {showLoading && (
                        <div className={`${styles.loadingContainer}`}>
                            <div>
                                <Loader />
                            </div>
                        </div>
                    )}

                    <div className={`${styles.modal} ${showLoading && styles.hidden}`}>
                        <button
                            type="button"
                            className={styles.closeButton}
                            onClick={onClose}
                            aria-label="Close pop up"
                        >
                            <XIcon />
                        </button>
                        <div className={styles.content}>{children}</div>
                        {showClose && (
                            <div className={styles.footer}>
                                <Button variant="primary" onClick={onClose}>
                                    {buttonText || 'Close'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Modal
