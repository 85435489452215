import { useEffect, useState } from 'react'
import styles from './loadingBar.module.scss'

function LoadingBar(): JSX.Element {
    // const [startTime, setStartTime] = useState<number>(0)
    const [completedPercentage, setCompletedPercentage] = useState<number>(100)
    const totalDuration = 40000
    const timeInterval = 25
    let startTime = 0

    function determineBarPosition(time: number): number {
        const axis = 1 - Math.min(time, totalDuration) / totalDuration
        const axisChangeSpeed = axis ** 4
        const posFormula = 100 * axisChangeSpeed
        return Math.floor(posFormula)
    }

    useEffect(() => {
        // const interval = setInterval(() => setCompletedPercentage(80), 1000)
        const interval = setInterval(() => {
            startTime += timeInterval
            setCompletedPercentage(determineBarPosition(startTime))
        }, timeInterval)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className={styles.loadingBar}>
            <div style={{ right: `${completedPercentage}%` }} />
        </div>
    )
}

export default LoadingBar
