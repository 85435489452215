import { Address } from '../types/address'

// Record<string, never> = explicit empty object

// eslint-disable-next-line import/prefer-default-export
export const createAddressObject = (address: string): Address | Record<string, never> => {
    const addressArray = address.split(',')

    if (addressArray.length !== 8) return {}

    const addressObject: Address = {
        line1: addressArray[0].trim(),
        line2: addressArray[1].trim(),
        line3: addressArray[2].trim(),
        line4: addressArray[3].trim(),
        locality: addressArray[4].trim(),
        town: addressArray[5].trim(),
        county: addressArray[6].trim(),
        postcode: addressArray[7].trim(),
    }

    return addressObject
}
