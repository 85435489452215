import { Promo } from '../promo.class'
import { corePromo } from './core-promo.config'

export const uswitchPromo = new Promo()
corePromo.phases.forEach((phase) => {
    uswitchPromo.addPhase({
        ...phase,
        pricebooks: {
            promoBook: 46,
            fullPriceBook: 32,
        },
    })
})
