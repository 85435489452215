import { createContext } from 'react'
import { isCTMHost, isUswitchCustomer } from 'utils/partners'
import { useCheckoutContext } from 'contexts/checkout'
import { getMandatoryEnvVar } from 'utils/env'
import { ctmPromo, corePromo, uswitchPromo } from './config'
import { PromoPhase } from './promo-phase.class'
import { Promo } from './promo.class'

const env = getMandatoryEnvVar('NODE_ENV')
export const PromoContext = createContext<{ config: Promo | null; phase: PromoPhase | null }>({
    config: null,
    phase: null,
})

interface Props {
    testingPromoConfig?: PromoPhase
    children: React.ReactNode
}
function PromoProvider({ testingPromoConfig, children }: Props) {
    const { state } = useCheckoutContext()

    const promoConfig = (() => {
        if (isCTMHost()) return ctmPromo
        if (isUswitchCustomer(state)) return uswitchPromo
        return corePromo
    })()

    let phase: PromoPhase | null = null
    let config: Promo | null = null
    if (env === 'test') {
        if (testingPromoConfig) {
            const prom = new Promo()
            prom.addPhase(testingPromoConfig)
            config = prom
        }
        phase = testingPromoConfig ?? null
    } else {
        config = promoConfig
        phase = promoConfig.getCurrentPhase()
    }

    return <PromoContext.Provider value={{ phase, config }}>{children}</PromoContext.Provider>
}

export default PromoProvider
