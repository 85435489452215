import { useCheckoutContext } from 'contexts/checkout'
import { useEffect, useState } from 'react'
import InputButtonGroup from 'components/inputButtonGroup'
import { usePromoCode } from './usePromocode'
import { useReferralCode } from './useReferralCode'
import styles from './index.module.scss'

function PromoAndReferralCode() {
    const { state, dispatch } = useCheckoutContext()

    const [promoReferralCode, setPromoReferralCode] = useState('')
    const [outputMessage, setOutputMessage] = useState('')
    const [isError, setIsError] = useState(false)

    const { refetchReferralCode } = useReferralCode(promoReferralCode, state.email)
    const { refetchPromoCode } = usePromoCode(promoReferralCode)

    const handleRemove = () => {
        dispatch({
            type: 'setCheckout',
            data: {
                promoCode: {},
                referralCode: '',
            },
        })
        setPromoReferralCode('')
        setOutputMessage('')
        setIsError(false)
    }

    // Reset the promocode on package change
    useEffect(() => {
        handleRemove()
    }, [state.packageId])

    const handleReferralCodeUpdate = async () => {
        const { isReferralError, isReferralValid, referralMessage } = await refetchReferralCode()

        setOutputMessage(referralMessage)
        setIsError(isReferralError)

        if (isReferralValid) {
            dispatch({
                type: 'setCheckout',
                data: {
                    referralCode: promoReferralCode,
                    promoCode: {},
                },
            })
        }
    }

    const handlePromoCodeUpdate = async () => {
        const { isPromoCodeError, isPromoCodeValid, promoCodeMessage, promoCodeData } =
            await refetchPromoCode()
        setOutputMessage(promoCodeMessage)
        setIsError(isPromoCodeError)
        if (isPromoCodeValid) {
            dispatch({
                type: 'setCheckout',
                data: {
                    promoCode: {
                        code: promoReferralCode,
                        value: promoCodeData?.value,
                        type: promoCodeData?.type,
                    },
                    referralCode: '',
                },
            })
        }
    }

    const onSubmit = async () => {
        if (promoReferralCode.startsWith('REF')) {
            await handleReferralCodeUpdate()
            return
        }

        await handlePromoCodeUpdate()
    }

    const onPromoReferralCodeChange = (value: string) => {
        handleRemove()
        setPromoReferralCode(value)
    }

    return (
        <div>
            <InputButtonGroup
                id="HomeCareCover_Checkout_Payment_ChangeReferralCode"
                dataTestId="promoReferralCode"
                onChange={(e) => onPromoReferralCodeChange(e.target.value)}
                onClick={onSubmit}
                buttonText="Apply"
                value={promoReferralCode}
                error={isError ? outputMessage : ''}
                disabled={!!outputMessage && !isError}
                label="Promo code/referral code"
            />

            {outputMessage && !isError && (
                <div className={styles.outputMessage}>
                    {outputMessage}{' '}
                    <button
                        id="HomeCareCover_Checkout_Payment_RemoveReferralCode"
                        type="button"
                        onClick={handleRemove}
                    >
                        Remove
                    </button>
                </div>
            )}
        </div>
    )
}

export default PromoAndReferralCode
