import { getLead } from 'api/lead'
import { CheckoutReducerAction, CheckoutState } from 'contexts/checkout/type'
import Cookies from 'js-cookie'
import { LeadDataType } from 'types/formData'
import { formatPostcode } from './formatPostcode'

export const leadToCookieData = (
    lead: LeadDataType['lead'],
    state: CheckoutState
): Partial<CheckoutState> => ({
    transactionId: state?.transactionId || lead.transaction_id,
    firstName: (state?.firstName || lead.first_name) ?? undefined,
    lastName: (state?.lastName || lead.last_name) ?? undefined,
    email: (state?.email || lead.email) ?? undefined,
    phone: (state?.phone || lead.phone) ?? undefined,
    querystring: (state?.querystring || lead.querystring) ?? undefined,
    source: (state?.source || lead.source) ?? undefined,
    postcode: state?.postcode || (lead.postcode ? formatPostcode(lead.postcode) : undefined),
})

export const storeLeadDataInCookie = async (
    dispatch: React.Dispatch<CheckoutReducerAction>,
    state: CheckoutState
) => {
    const cookieLeadToken = Cookies.get('lead-token')
    if (cookieLeadToken) {
        const leadData: LeadDataType | null = await getLead(cookieLeadToken)
        if (leadData && !leadData.expired) {
            const { lead } = leadData
            const newCookieData = leadToCookieData(lead, state)
            dispatch({
                type: 'setCheckout',
                data: newCookieData,
            })
        }
    }
}
