import { useCheckoutContext } from 'contexts/checkout'
import LoadingBar from 'routes/register/components/loadingBar'
import { PHONE_NUMBER_LINK, PHONE_NUMBER } from 'config/constants'
import { isCTMHost } from 'utils/partners'
import styles from './index.module.scss'

function RegisterCustomer(): JSX.Element {
    const { state } = useCheckoutContext()

    const renderError = () => {
        if (state.registerCustomerRequest?.errorCode === 'PropertyHasLiveOrFutureContract') {
            return (
                <h3>
                    A contract for this property already exists. Please call
                    <a href={PHONE_NUMBER_LINK}>{` ${PHONE_NUMBER} `}</a> to speak to our team.
                </h3>
            )
        }

        if (state.registerCustomerRequest?.errorCode === 'EmailAlreadyExists') {
            return (
                <h3>
                    This account already exists. Please check your information and try again, or
                    call <a href={PHONE_NUMBER_LINK}>{` ${PHONE_NUMBER} `}</a> to speak to our team.
                </h3>
            )
        }

        return (
            <h3>
                There was an error, please give us a call so we can assist you better
                <a href={PHONE_NUMBER_LINK}>{` ${PHONE_NUMBER} `}</a>
            </h3>
        )
    }
    return (
        <div className={styles.registerCustomer}>
            {!state.registerCustomerRequest ||
            state.registerCustomerRequest.isError ||
            !state.registerCustomerRequest.isRequesting ? (
                renderError()
            ) : (
                <>
                    <LoadingBar />
                    <div>
                        <h3>
                            Hold tight - we&apos;re setting up your{' '}
                            {isCTMHost() ? 'care plan' : 'cover plan'}
                            .<br />
                        </h3>
                        <h4>
                            While you wait... <br className={styles.showMobile} />
                            Did you know you can refer a friend <br />
                            and both receive a
                            <span className={styles.highlight}> £50 gift card?</span>
                        </h4>

                        <h4>See welcome email for details.</h4>
                    </div>
                </>
            )}
        </div>
    )
}

export default RegisterCustomer
