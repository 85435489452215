import { Routes, Route } from 'react-router-dom'

import Feedback from 'routes/feedback'
import Checkout from './checkout'
import Confirmation from './confirmation'
import RegisterCustomer from './register'
import Success from './success'

function DefaultRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/register-customer" Component={RegisterCustomer} />
            <Route path="/confirmation" Component={Confirmation} />
            <Route path="/feedback" Component={Feedback} />
            <Route path="/success" Component={Success} />
            <Route path="/checkout" Component={Checkout} />
            <Route path="/" Component={Checkout} />
        </Routes>
    )
}

export default DefaultRoutes
