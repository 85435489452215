import { Promo } from '../promo.class'

export const corePromo = new Promo()

corePromo.addPhase({
    period: {
        start: new Date(2024, 10, 14, 9, 0, 0),
        end: new Date(2024, 11, 3, 23, 59, 59),
    },

    countdown: true,
    pricebooks: {
        promoBook: 59,
        fullPriceBook: 39,
    },
    banner: {
        primaryText: <>Black Friday Deal - Up to 30% off&nbsp;</>,
        primaryTextMobile: <>Black Friday Deal - Up to 30% off&nbsp;</>,
        secondaryText: (
            <>
                <span>
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2024/11/Black-Friday-Sale-2024-Up-to-30-Off-Promotion-Offer-Terms.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    apply
                </span>
            </>
        ),
    },
    checkout: {
        strikethrough: true,
    },
})
