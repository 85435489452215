import axios from 'axios'
import { PROPERTIES_ENDPOINT } from 'config/constants'

const API_BASE_URL = process.env.REACT_APP_CHECKOUT_API_BASE_URL

interface ValidatePropertyResponse {
    contractExist: boolean
}

export const validatePropertyContract = async (postcode: string, street: string) => {
    const responseTest = await axios.get<ValidatePropertyResponse>(
        `${API_BASE_URL}/${PROPERTIES_ENDPOINT}/pub/v1/properties/validate?postcode=${postcode}&street=${street}`
    )

    return responseTest.data.contractExist
}
