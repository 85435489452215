import BubbleHeading from 'components/bubbleHeading'
import styles from './index.module.scss'

function Success(): JSX.Element {
    return (
        <>
            <BubbleHeading>Welcome to Hometree!</BubbleHeading>

            <div className={styles.form}>
                <section>
                    <h2>Thank you!</h2>
                    <p>Your feedback helps us learn and grow.</p>

                    <h2>What now?</h2>
                    <p>
                        Check your inbox for your welcome email, which includes your policy details.
                        (Don’t forget to check your spam folder, in case it’s landed there!)
                        <br />
                        <br />
                        Your cover starts on the day you selected at checkout. In the meantime, if
                        you have any questions please head over to our{' '}
                        <a
                            target="_blank"
                            href="https://www.hometree.co.uk/help-centre/"
                            rel="noreferrer"
                        >
                            Help Centre
                        </a>
                        .
                    </p>
                </section>
            </div>
        </>
    )
}

export default Success
