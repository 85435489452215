import { CheckoutState } from 'contexts/checkout/type'

export const isCTMHost = () => {
    if (
        // Added to /etc/hosts for local development
        window.location.hostname === 'ctm.hometree.local' ||
        window.location.hostname === 'hometree.comparethemarket.com' ||
        window.location.hostname === 'ctm.stage.hometree.co.uk' ||
        window.location.hostname === 'dp7kgzkil2n3k.cloudfront.net' || // stage CTM cloudfront
        window.location.hostname === 'd35v7ijmru50xp.cloudfront.net' // prod CTM cloudfront
    ) {
        return true
    }

    return false
}

export const isUswitchCustomer = (state: CheckoutState) => {
    return state.landingPage?.includes('uswitch')
}

export const isSoEnergyCustomer = (state: CheckoutState) => {
    return !state.httpReferrer?.includes('so.energy') && state.querystring?.includes('soenergy')
}
