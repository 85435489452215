import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools' // Bug with this version on jest, commenting out for now
import ScrollToTop from 'features/scrollToTop'
import Header from 'features/header'
import PromoProvider from 'features/promoMode'
import Analytics from './features/analytics'
import Footer from './features/footer'
import { CheckoutProvider } from './contexts/checkout'
import styles from './App.module.scss'
import Router from './routes'
import './main.css'

const queryClient = new QueryClient()

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <CheckoutProvider>
                <QueryClientProvider client={queryClient}>
                    <PromoProvider>
                        <div className={styles.container}>
                            <Analytics />
                            <Header />
                            <Router />
                            <Footer />
                        </div>
                    </PromoProvider>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </QueryClientProvider>
            </CheckoutProvider>
        </BrowserRouter>
    )
}

export default App
