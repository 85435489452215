import Button from 'components/button'
import styles from './index.module.scss'

function ContactUs() {
    return (
        <div className={`${styles.container}`}>
            <div className={styles.content}>
                <h1>Contact us today</h1>
                <div>
                    <p>Our lines are open</p>
                    <p>Monday to Friday: 9am - 5pm</p>
                    <p>Saturday and Sunday: Closed</p>
                    <div className={styles.ctas}>
                        <a href="tel:03309124843">
                            <Button>Call our support team</Button>
                        </a>
                        <a href="https://www.hometree.co.uk/terms-and-conditions/">
                            <Button variant="transparent">Full terms & conditions</Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
