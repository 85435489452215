import { ReactComponent as XIcon } from 'assets/x-dark-green.svg'
import { useEffect } from 'react'
import styles from './index.module.scss'

interface Props {
    bannerEnabled: boolean
    setBannerEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export function SoEnergyBanner({ bannerEnabled, setBannerEnabled }: Props) {
    useEffect(() => {}, [bannerEnabled])
    return (
        <>
            {bannerEnabled && (
                <div className={styles.banner} data-testid="so-energy-banner">
                    <span />
                    <div className={`${styles.mobile}`}>
                        <span className={styles.content}>
                            <span className={styles.tc}>
                                <h3>Receive £50 credit on So Energy energy bill after purchase</h3>
                            </span>
                        </span>
                    </div>

                    <div className={`${styles.desktop}`}>
                        <div className={styles.content}>
                            <span className={styles.tc}>
                                <h3>
                                    So Energy Exclusive Offer: Receive £50 credit on your energy
                                    bill after purchase
                                </h3>
                            </span>
                        </div>
                    </div>
                    <span className={styles.closeButton}>
                        <XIcon onClick={() => setBannerEnabled(false)} />
                    </span>
                </div>
            )}
        </>
    )
}
