import { ChangeEvent, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { GCDropinOptions } from 'api/goCardless'
import Modal from 'components/modal'
import Radio from 'components/radio'
import { ReactComponent as DirectDebit } from 'assets/logos/directDebit.svg'
import { ReactComponent as Visa } from 'assets/logos/visa.svg'
import { ReactComponent as MasterCard } from 'assets/logos/mastercard.svg'
import { useCheckoutContext } from 'contexts/checkout'
import { useNavigate } from 'react-router-dom'
import Button from 'components/button'
import StartDate from './components/startDate'
import { TermsAndConditions } from './termsAndConditions'
import PromoAndReferralCode from './components/promoAndReferralCode'
import ChargebeeCardComponent, { ChargebeeCardComponentRef } from '../chargebeeCardComponent'
import styles from './index.module.scss'
import { handleGCSuccess } from '../handleThirdPartyCallbacks'
import GoCardlessDropInComponent, { GCDropInRef } from '../goCardlessDropInComponent/index'

interface DateFormSectionProps {
    setDateSectionValid: (value: boolean) => void
    gcDropInOptions: GCDropinOptions
}

function StartDateAndPayment({
    setDateSectionValid,
    gcDropInOptions,
}: DateFormSectionProps): JSX.Element {
    const [openModal, setOpenModal] = useState(false)
    const [openDDGuaranteeModal, setOpenDDGuaranteeModal] = useState(false)
    const [termsCheckbox, setTermsCheckbox] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState<'directDebit' | 'cardPayment'>('directDebit')
    const [error, setError] = useState<string>('')
    const navigate = useNavigate()

    const { dispatch } = useCheckoutContext()
    const gcDropInRef = useRef<GCDropInRef>(null)
    const chargebeeCardComponentRef = useRef<ChargebeeCardComponentRef>(null)

    useEffect(() => {
        setDateSectionValid(!!termsCheckbox)
    }, [termsCheckbox])

    const handlePaymentMethodChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target == null) return

        setPaymentMethod(e.target.name as 'directDebit' | 'cardPayment')
    }

    const onMakePaymentClick = () => {
        if (gcDropInRef && gcDropInRef.current && paymentMethod === 'directDebit') {
            gcDropInRef.current.handleGCDropInOpen()
        }

        if (
            chargebeeCardComponentRef &&
            chargebeeCardComponentRef.current &&
            paymentMethod === 'cardPayment'
        ) {
            chargebeeCardComponentRef.current.handleChargebeeModalOpen()
        }
    }

    // TODO still needed?
    const handleGCDropInExit = (e: object | null) => {
        if (e) {
            Sentry.captureException(e)
            setError('Network Error connecting to the API')
        }
    }

    return (
        <>
            <StartDate />
            <br />
            <PromoAndReferralCode />

            <div className={styles.paymentMethodList} data-testid="payment-method-list">
                <h3>Payment method</h3>
                <ul>
                    <li>
                        <Radio
                            id="HomeCareCover_Checkout_Payment_SelectTypeDD"
                            onChange={handlePaymentMethodChange}
                            checked={paymentMethod === 'directDebit'}
                            name="directDebit"
                        >
                            <div className={styles.eachPaymentMethod}>
                                <span>Direct Debit</span>
                                <div className={styles.directDebitLogo}>
                                    <DirectDebit />
                                </div>
                            </div>
                        </Radio>
                    </li>
                    <li>
                        <Radio
                            id="HomeCareCover_Checkout_Payment_SelectTypeCC"
                            onChange={handlePaymentMethodChange}
                            checked={paymentMethod === 'cardPayment'}
                            name="cardPayment"
                        >
                            <div className={styles.eachPaymentMethod}>
                                <span>Card Payment</span>
                                <div>
                                    <Visa />
                                    <MasterCard />
                                </div>
                            </div>
                        </Radio>
                    </li>
                </ul>

                <hr />
            </div>

            <div>
                <TermsAndConditions
                    termsCheckbox={termsCheckbox}
                    setTermsCheckbox={setTermsCheckbox}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            </div>

            <div id="gc-dropin-button-container" className={styles.button}>
                <div id="make-payment-button">
                    {gcDropInOptions ? (
                        <Button
                            id="HomeCareCover_Checkout_Payment_SubmitOld"
                            type="button"
                            onClick={onMakePaymentClick}
                            data-testid="make-payment-button"
                            className={styles.makePaymentButton}
                            disabled={!termsCheckbox}
                        >
                            Buy now (via Direct Debit)
                        </Button>
                    ) : (
                        // TODO Add a spinner here
                        <p>Loading checkout...</p>
                    )}
                </div>
                <div id="make-payment-button-new" className={styles.hideNewPaymentButton}>
                    {gcDropInOptions ? (
                        <Button
                            id="HomeCareCover_Checkout_Payment_Submit"
                            type="button"
                            onClick={onMakePaymentClick}
                            data-testid="make-payment-button"
                            className={styles.makePaymentButton}
                            disabled={!termsCheckbox}
                        >
                            Make payment
                        </Button>
                    ) : (
                        // TODO Add a spinner here
                        <p>Loading checkout...</p>
                    )}
                </div>
            </div>

            <div>
                <div>{error}</div>
                {gcDropInOptions && (
                    <GoCardlessDropInComponent
                        gcDropInOptions={{
                            billingRequestFlowID: gcDropInOptions.flowID,
                            environment: gcDropInOptions.environment.toLowerCase(),
                            onSuccess: handleGCSuccess(dispatch, navigate),
                            onExit: handleGCDropInExit,
                        }}
                        ref={gcDropInRef}
                    />
                )}
                <div data-testid="tc-blurb">
                    <p>
                        I understand that my personal data will be processed in accordance with
                        Hometree’s{' '}
                        <a
                            href="https://www.hometree.co.uk/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        , and that Hometree will never share or sell my personal data without my
                        consent or another lawful basis.
                    </p>

                    <p>
                        We will take payment within 5 working days of your plan&apos;s start date.
                        This 12 month contract auto-renews. We will send you a reminder email 30
                        days before renewal. Pay by Direct Debit Guarantee.
                    </p>
                </div>

                <div data-testid="tc-blurb-new" className={styles.hideNewTCsBlurb}>
                    <p>
                        I understand that my personal data will be processed in accordance with
                        Hometree’s{' '}
                        <a
                            href="https://www.hometree.co.uk/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        , and that Hometree will never share or sell my personal data without my
                        consent or another lawful basis.
                    </p>
                    {paymentMethod === 'directDebit' && (
                        <p>
                            We will take payment within 5 working days of your plan&apos;s start
                            date. This 12 month contract auto-renews. We will send you a reminder
                            email 30 days before renewal. Pay by {/* eslint-disable-next-line */}
                            <a
                                onClick={(e) => {
                                    e.preventDefault()
                                    setOpenDDGuaranteeModal(true)
                                }}
                                href=""
                            >
                                Direct Debit Guarantee
                            </a>
                            .
                        </p>
                    )}

                    {paymentMethod === 'cardPayment' && (
                        <p>
                            We will take payment on the day of your plan’s start date. This 12 month
                            contract will auto-renew. We will send you a reminder email 30 days
                            before renewal. Payments are secure and encrypted.
                        </p>
                    )}
                </div>
            </div>

            <ChargebeeCardComponent ref={chargebeeCardComponentRef} />

            <Modal isOpen={openModal} onClose={() => setOpenModal(false)} buttonText="Okay">
                <h2>Good working order</h2>
                <p>
                    We won’t be able to fix anything that is broken before you take out one of our
                    care plans. Please make sure that all items you would like us to include are in
                    full working order and have no pre-existing issues at the time of signing up.
                </p>
                <p>
                    This includes any faults in your home, from broken boilers to leaky pipes and
                    broken fuse boxes.
                </p>
            </Modal>

            <Modal
                isOpen={openDDGuaranteeModal}
                onClose={() => setOpenDDGuaranteeModal(false)}
                buttonText="Okay"
            >
                <h2>Direct Debit Guarantee</h2>
                <ul>
                    <li>
                        The Guarantee is offered by all banks and building societies that accept
                        instructions to pay Direct Debits
                    </li>
                    <li>
                        If there are any changes to the amount, date or frequency of your Direct
                        Debit the organisation will notify you (normally 10 working days) in advance
                        of your account being debited or as otherwise agreed. If you request the
                        organisation to collect a payment, confirmation of the amount and date will
                        be given to you at the time of the request
                    </li>
                    <li>
                        If an error is made in the payment of your Direct Debit, by the organisation
                        or your bank or building society, you are entitled to a full and immediate
                        refund of the amount paid from your bank or building society
                    </li>
                    <li>
                        If you receive a refund you are not entitled to, you must pay it back when
                        the organisation asks you to
                    </li>
                    <li>
                        You can cancel a Direct Debit at any time by simply contacting your bank or
                        building society. Written confirmation may be required. Please also notify
                        the organisation.
                    </li>
                </ul>
            </Modal>
        </>
    )
}

export default StartDateAndPayment
