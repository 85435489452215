import { PaymentIntent } from '@chargebee/chargebee-js-types'
import { INTEGRATION_ENDPOINT } from 'config/constants'
import { CheckoutState } from 'contexts/checkout/type'

const API_BASE_URL = process.env.REACT_APP_PORTAL_API_BASE_URL

interface RequestPayload {
    package_id: number
    pricebook_id: number
    package_type: string
    billing_type: 'monthly' | 'annual'
    contribution: number
    addons?: { id: string; quantity: number }[]
    promocode?: string
}

interface ResponsePayload {
    paymentIntent: PaymentIntent
}

export const chargebeeGeneratePaymentIntent = async (state: CheckoutState) => {
    try {
        if (!state.packageId) {
            throw new Error('Package ID is not defined')
        }
        if (!state.pricebookId) {
            throw new Error('Pricebook is not defined')
        }

        const input: RequestPayload = {
            package_id: state.packageId,
            pricebook_id: state.pricebookId,
            package_type: state.packageType,
            billing_type: state.billingType,
            contribution: state.contribution * 100,
            promocode: state.promoCode?.code,
        }

        const response = await fetch(
            `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/chargebee/payment-intent`,
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify(input),
            }
        )

        const {
            data: { paymentIntent },
        } = (await response.json()) as { data: ResponsePayload }

        return { ...paymentIntent }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error when generating chargebee payment intent', error)
        throw error
    }
}
