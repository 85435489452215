import { PROMOCODES_ENDPOINT } from 'config/constants'
import { QueryFunction } from '@tanstack/react-query'

const API_BASE_URL = process.env.REACT_APP_CHECKOUT_API_BASE_URL

export type PackageToPromoCode = {
    id: number
    active: number
    package_id: number
    package_name: string
    promocode_id: number
}
export type ValidatePromoCodeResponse = {
    data: {
        isValid: boolean
        id: number
        code: string
        pricebook: unknown
        type: number
        value: number
        product_to_promocode: unknown
        package_to_promocode?: PackageToPromoCode[]
    }
}

type ValidatePromoCodeQueryKey = [string, { promoCode: string }]

export const validatePromoCode: QueryFunction<
    ValidatePromoCodeResponse['data'],
    ValidatePromoCodeQueryKey
> = async ({ queryKey }) => {
    const [, { promoCode }] = queryKey

    const url = `${API_BASE_URL}/${PROMOCODES_ENDPOINT}/promoCode/validate/${promoCode}`
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    })
    // Fetch does not automatically throw errors
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    const json: ValidatePromoCodeResponse = await response.json()
    return json.data
}
