export const calculateTimeLeft = (endDatetime: Date) => {
    const difference = +endDatetime - +new Date()

    const timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    }
    if (difference > 0) {
        timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24))
        timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60)
        timeLeft.seconds = Math.floor((difference / 1000) % 60)
    }
    return timeLeft
}
