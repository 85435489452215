import { mapRegisterCustomerPayload } from 'utils/mapRegisterCustomerPayload'

export const validateRegisterCustomerBody = (
    body: ReturnType<typeof mapRegisterCustomerPayload>
) => {
    // Check for GoCardless details
    if (
        (body.go_cardless === undefined ||
            body.go_cardless.mandate_request_mandate_id === undefined ||
            body.go_cardless.mandate_request_mandate_id === '' ||
            body.go_cardless.mandate_request_mandate_id === null) &&
        (body.chargebee === undefined ||
            body.chargebee.payment_intent_id === undefined ||
            body.chargebee.payment_intent_id === '' ||
            body.chargebee.payment_intent_id === null)
    ) {
        return {
            valid: false,
            errorMessage: 'Neither GoCardless nor Chargebee details are present',
        }
    }

    if (!body.postcode) {
        return {
            valid: false,
            errorMessage: 'Postcode is not present',
        }
    }

    return {
        valid: true,
    }
}
