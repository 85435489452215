// pass in hostname to simplify testing
export const generateGcSuccessUrl = (hostname: string): string => {
    switch (hostname) {
        case 'app':
        case 'localhost':
            return `http://${hostname}:3000/validation`
        case 'checkout.dev.hometree.co.uk':
            return `http://${hostname}/validation`
        default:
            return `https://${hostname}/validation`
    }
}
